import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  getCombinationController: null,
};

export const controllerSlice = createSlice({
  name: 'apicontroller',
  initialState,
  reducers: {
    setCombinationController: (state, { payload }) => {
      state.getCombinationController = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCombinationController } = controllerSlice.actions;

export default controllerSlice.reducer;
