import axios from 'axios';
import { getToken } from '../../../Utils/settings';
import { getCandleDataUrl, getCombinationUrl, getSymbolsUrl } from '../Api';
import { setCombinationController } from '../../../redux/apiControllerSlice/apiControllerSlice';
import { setCombinationLoader } from '../../../redux/dashboardSlice/dashSlice';

export const getCandleDataAction = ({ payload }) => {
  const tokenHeader = getToken();

  return new Promise((resolve, reject) => {
    axios
      .post(getCandleDataUrl, payload, tokenHeader)
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};

export const getCombinationAction = ({
  payload,
  page_number,
  getCombinationController,
  dispatch,
}) => {
  const tokenHeader = getToken();

  const newController = new AbortController();

  // If there is a current request, abort it before making a new one
  if (getCombinationController) {
    dispatch(setCombinationLoader(true));
    getCombinationController.abort();
  }
  dispatch(setCombinationController(newController));

  return new Promise((resolve, reject) => {
    axios
      .post(`${getCombinationUrl}?page=${page_number}&page_size=10`, payload, {
        ...tokenHeader,
        signal: newController.signal,
      })
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};

export const getSymbolsAction = () => {
  const tokenHeader = getToken();
  return new Promise((resolve, reject) => {
    axios
      .post(
        getSymbolsUrl,
        {},
        {
          ...tokenHeader,
        }
      )
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};
