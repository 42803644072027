import {
  Button,
  Spin,
  Tooltip,
  notification,
} from 'antd';
import React, { useEffect, useState } from 'react';
import {
  getRandomAlphabet,
  getRandomColor,
  trueFalse,
} from '../../../Utils/settings';
import {
  CheckOutlined,
  FilterOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAngleColumnAction,
  getAngleColumnValuesAction,
  getPlanetAnglesAction,
} from '../../../Screens/Action';
import {
  resetSelectedAngles,
  setAlphabets,
  setIndicatorData,
  setPlanetAngles,
  setSelectedAngles,
} from '../../../redux/planetSlice/planetSlice';
import { NoData } from '../../NoData';
import PlanetAnglesFilter from './PlanetAnglesFilter';

const PlanetAnglesSelection = ({ data, loading }) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [color] = useState(getRandomColor());
  const [alphabet, setAlphabet] = useState();

  useEffect(() => {
    setChecked(false);
  }, [loading]);

  const { planet_angles, indicatorData, selectedAlphabets } = useSelector(
    (state) => state.planet
  );

  const onChecked = (checked) => {
    const filteredAngles = planet_angles
      .map((item) => ({
        [data]: item[data],
        date: item.date,
        ...item,
      }))
      .filter((vv) => vv[data]);

    if (filteredAngles.length === 0 || !filteredAngles[0].date)
      return notification.error({ message: 'Error In Displaying Data' });

    const isLordShip = trueFalse.test(filteredAngles[0][data]);

    const dataWord = data[0];

    let word = '';

    if (isLordShip) {
      if (selectedAlphabets.includes(dataWord)) {
        do {
          word = getRandomAlphabet(word ? word : dataWord);
        } while (selectedAlphabets.includes(word));

        setAlphabet(word);
      } else {
        word = dataWord;
        setAlphabet(word);
      }
    } else word = null;

    setChecked(checked);

    if (checked) {
      dispatch(
        setSelectedAngles({
          type: 'ADD',
          data: data,
          color: color,
          alphabet: word,
        })
      );

      if (isLordShip) dispatch(setAlphabets({ type: 'ADD', data: word }));
    } else {
      dispatch(setSelectedAngles({ type: 'REMOVE', data: data }));
      if (isLordShip)
        dispatch(setAlphabets({ type: 'REMOVE', data: alphabet }));
    }
  };

  const isIndicator = !!indicatorData[data];

  return (
    <div className='checkbox-ctr'>
      <div
        className={`customCheckbox ${checked && 'checked'}`}
        onClick={() => onChecked(!checked)}
      >
        <CheckOutlined className={`customCheck ${!checked && 'uncheck'}`} />
      </div>
      {data}

      {/* <Checkbox checked={checked} onChange={onChecked}>
      </Checkbox> */}
      {checked && (
        <>
          <div
            className='dot'
            style={{
              backgroundColor: isIndicator
                ? indicatorData[data][0].color
                : color,
            }}
          />

          {alphabet && <div>{alphabet}</div>}
        </>
      )}
    </div>
  );
};

export default function PlanetAnglesSection({
  planetAnglesInitialValues,
  setplanetAnglesInitialValues,
  dontRefresh = false,
  setdontRefresh = () => {},
}) {
  const { searchParams } = useSelector((state) => state.dashboard);
  const { planet_angles, selectedAlphabets } = useSelector(
    (state) => state.planet
  );

  const dispatch = useDispatch();

  const [openModal, setopenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const clearChart = () => {
    dispatch(resetSelectedAngles());
    dispatch(setPlanetAngles([]));
    dispatch(setIndicatorData({}));
    dispatch(setAlphabets({ type: '', data: '' }));
  };

  const getData = async () => {
    if (dontRefresh) return setdontRefresh();
    if (planetAnglesInitialValues.planets.length === 0) return;

    const payload = [
      {
        ...planetAnglesInitialValues,
        symbol: searchParams.symbol,
      },
    ];

    setLoading(true);
    await getPlanetAnglesAction({
      payload,
      page_Number: 1,
    })
      .then(({ message, error, data }) => {
        if (!error) {
          data.sort((a, b) => new Date(a.date) - new Date(b.date));

          const keys = Object.keys(data[0]).filter(
            (element) =>
              !['Open', 'High', 'Low', 'Close', 'date'].includes(element)
          );

          // Phase 2: Data Format
          let newObj = {};
          keys.forEach((key) => {
            const color = getRandomColor();
            newObj[key] = data.map((item) => {
              return {
                value: item[key],
                date: item.date,
                color: color,
              };
            });
          });

          let indicatorObj = {};
          keys.forEach((key) => {
            const regex = /^-?[0-9]+(\.[0-9]+)?$/;
            const dataIsString = !regex.test(
              newObj[key].filter((vv) => vv.value)[0]?.value
            );
            if (!dataIsString) {
              indicatorObj[key] = newObj[key];
            }
          });

          dispatch(setIndicatorData(indicatorObj));
          dispatch(resetSelectedAngles());
          dispatch(setPlanetAngles(data));
          dispatch(setAlphabets({ type: '', data: '' }));
        } else {
          clearChart();
          notification.error({ message });
        }
      })
      .catch(() => {
        notification.error({ message: 'Something went wrong' });
        clearChart();
      });
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [searchParams, planetAnglesInitialValues]);

  function modalClosed() {
    setopenModal(false);
  }

  function onFinish(values) {
    const data = { ...planetAnglesInitialValues, ...values };
    setplanetAnglesInitialValues(data);

    clearChart();
    setopenModal(false);
  }

  return (
    <>
      <PlanetAnglesFilter
        initialVlaues={planetAnglesInitialValues}
        modalClosed={modalClosed}
        onFinish={onFinish}
        openModal={openModal}
      />

      {/* <FilterModal /> */}
      <div className='filter_ctr'>
        <Tooltip placement='left' title='Filter Angles'>
          <Button
            className='filter_icon'
            onClick={() => setopenModal(true)}
            icon={<FilterOutlined />}
          />
        </Tooltip>
      </div>
      <Spin
        tip={<p className='loadingtext'>Loading Chart Data...</p>}
        spinning={loading}
      >
        {planet_angles?.length > 0 ? (
          Object.keys(planet_angles[0])
            .filter(
              (element) =>
                !['Open', 'High', 'Low', 'Close', 'date'].includes(element)
            )
            .map((item) => {
              return <PlanetAnglesSelection data={item} loading={loading} />;
            })
        ) : loading ? null : (
          <NoData />
        )}
      </Spin>
    </>
  );
}
