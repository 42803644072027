import React, { useEffect } from 'react';
import TermsAndServices from '../../../Components/TermsAndConditions/TermsAndServices';

const TermsServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <TermsAndServices />
    </div>
  );
};

export default TermsServices;
