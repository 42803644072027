import React from 'react';

import './Benefits.css';
import './pricing.css';

import BENEFITONE from '../Assets/benefit_1.png';
import BENEFITTWO from '../Assets/benefit_2.png';
import BENEFITTHREE from '../Assets/benefit_3.png';
import BENEFITFOUR from '../Assets/benefit_4.png';
import { Link } from 'react-router-dom';

const Benefits = () => {
  function GoPremiumBtn({ text = 'Go Premium' }) {
    return (
      <Link to={'/auth/login'} className='benefit_plan_btn' target='_blank'>
        {text}
      </Link>
    );
  }
  
  return (
    <div className='benefits-ctr'>
      <h2 className='ctr-header'>Why PickMyTrade Stands Out</h2>

      <div className='all-benefits'>
        <div className='div1'>
          <div className='right-section-mobile'>
            <img src={BENEFITONE} alt='' />
          </div>
          <div className='left-section'>
            <h3 className='left-header'>
              In-Depth Astrological Market Analysis
            </h3>
            <p>
              Our experts delve into the astrological aspects affecting the
              markets, focusing on pivotal points where significant celestial
              events align with market movements. By studying these pivot points
              and comparing them with upcoming similar celestial alignments
              within a +/- 2-day window, we provide predictive insights that are
              both precise and insightful.
            </p>
          </div>
          <div className='right-section'>
            <img src={BENEFITONE} alt='' />
          </div>
        </div>

        <div className='div1'>
          <div className='right-section-mobile'>
            <img src={BENEFITTWO} alt='' />
          </div>
          <div className='right-section'>
            <img src={BENEFITTWO} alt='' />
          </div>
          <div className='left-section'>
            <h3 className='left-header'>Interactive Astrological Calendar</h3>
            <p>
              Gain access to our dynamic calendar, showcasing astrological
              events and their historical impact on the markets. This feature
              allows you to see at a glance the success rate of specific events
              based on historical data, making it easier to predict future
              market trends.
            </p>
          </div>
        </div>

        <div className='div1'>
          <div className='right-section-mobile'>
            <img src={BENEFITTHREE} alt='' />
          </div>
          <div className='left-section'>
            <h3 className='left-header'>Customizable Event Analysis</h3>
            <p>
              Personalize your trading strategy with the ability to select and
              visualize the impact of various astrological events on the market.
              Our platform's intuitive design lets you explore and analyze the
              significance of these events, providing a clear view of how they
              could influence market dynamics. This tool is especially
              beneficial for swing traders looking for the right moment to enter
              or exit the market.
            </p>
          </div>
          <div className='right-section'>
            <img src={BENEFITTHREE} alt='' />
          </div>
        </div>

        <div className='div1'>
          {/* Mobile Screen */}
          <div className='right-section-mobile'>
            <img src={BENEFITFOUR} alt='' />
          </div>

          {/* Big Screen */}
          <div className='right-section'>
            <img src={BENEFITFOUR} alt='' />
          </div>
          <div className='left-section'>
            <h3 className='left-header'>
              Real-Time Astrological Alerts & Education
            </h3>
            <p>
              Stay ahead with alerts on crucial astrological events and their
              anticipated effects on the markets. Additionally, our extensive
              educational resources are tailored to help both novices and
              experienced traders understand the principles of financial
              astrology and how to apply them to real-world trading.
            </p>
          </div>
        </div>
      </div>

      {/* <div className='parent'>
        <div className='div1 common'>
          In-Depth Astrological Market Analysis: Our experts delve into the
          astrological aspects affecting the markets, focusing on pivotal points
          where significant celestial events align with market movements. By
          studying these pivot points and comparing them with upcoming similar
          celestial alignments within a +/- 2-day window, we provide predictive
          insights that are both precise and insightful.
        </div>
        <div className='div2 common'>
          Interactive Astrological Calendar: Gain access to our dynamic
          calendar, showcasing astrological events and their historical impact
          on the markets. This feature allows you to see at a glance the success
          rate of specific events based on historical data, making it easier to
          predict future market trends.
        </div>
        <div className='div3 common'>
          Customizable Event Analysis: Personalize your trading strategy with
          the ability to select and visualize the impact of various astrological
          events on the market. Our platform's intuitive design lets you explore
          and analyze the significance of these events, providing a clear view
          of how they could influence market dynamics. This tool is especially
          beneficial for swing traders looking for the right moment to enter or
          exit the market.
        </div>
        <div className='div4 common'>
          Real-Time Astrological Alerts & Education: Stay ahead with alerts on
          crucial astrological events and their anticipated effects on the
          markets. Additionally, our extensive educational resources are
          tailored to help both novices and experienced traders understand the
          principles of financial astrology and how to apply them to real-world
          trading.
        </div>
      </div> */}

      <GoPremiumBtn text='Get Started' />
    </div>
  );
};

export default Benefits;
