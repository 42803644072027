import { DownloadOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React, { useState } from 'react';
import { downloadCombinationsAction } from '../../../Screens/Action';
import { useSelector } from 'react-redux';

export default function DownloadCombination({ initialFilterObj }) {
  const [csvLoading, setCsvLoading] = useState(false);
  const [currentReq, setCurrentReq] = useState();

  let { searchParams } = useSelector((state) => state.dashboard);

  const getCsvData = async () => {
    try {
      setCsvLoading(true);

      await downloadCombinationsAction({
        payload: [
          {
            exchange_name: searchParams.exchange_name,
            symbol: searchParams.symbol,
            mode: initialFilterObj.mode,
          },
        ],
        currentReq,
        setCurrentReq: (e) => setCurrentReq(e),
      }).catch((err) => console.log(err));
      setCsvLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Tooltip
      placement='left'
      title={csvLoading ? 'Fetching Data...' : 'Download Combinations'}
    >
      <Button
        loading={csvLoading}
        onClick={getCsvData}
        icon={<DownloadOutlined />}
        className='download_icon'
      >
        CSV File
      </Button>
    </Tooltip>
  );
}
