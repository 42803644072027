import React, { useEffect, useState } from 'react';

import Verified from './../../Assets/verified.png';
import Error from './../../Assets/cross.png';
import { Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { verifyEmailAction } from './Action';

const VerificationComplete = () => {
  const navigate = useNavigate();
  const [isverified, setVerified] = useState(true);

  const onVerify = async (values) => {
    await verifyEmailAction({ email_token: values })
      .then(({ error, message }) => {
        if (error) {
          setVerified(false);
        } else setVerified(true);
      })
      .catch(() => setVerified(false));
  };

  useEffect(() => {
    const currentUrl = window.location.hash;

    if (currentUrl.includes('email_token')) {
      const email_token = currentUrl.split('=')[1];
      onVerify(email_token);
    } else {
      navigate('/auth/login', { replace: true });
    }
  }, []);

  return (
    <div className='verified-ctr'>
      <img src={isverified ? Verified : Error} className='verified-img' />
      <Typography.Text className='verified-text'>
        {isverified
          ? "Your verification was successful. You are now officially confirmed and can enjoy full access to your account. Thank you for choosing us!{' '}"
          : 'We were unable to verify your account. If the problem persists, contact support for assistance.'}{' '}
        <span
          onClick={() => {
            if (isverified) {
              navigate('/auth/login', { replace: true });
            } else {
              navigate('/', { replace: true });
            }
          }}
        >
          {isverified ? 'Login Now' : 'Home Page'}
        </span>
      </Typography.Text>
    </div>
  );
};

export default VerificationComplete;
