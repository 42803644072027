import React, { useEffect, useState } from 'react';

import './subscription.css';
import {
  billingCycleEnums,
  currencyEnums,
  getKeyByValue,
  plansBenefits,
} from '../../Utils/settings';
import { notification } from 'antd';
import {
  createPhonePeSubscriptionAction,
  getPhonePeSubscriptionProductAction,
  phonePeGetActiveSubscriptionAction,
  phonePeVerifyTransactionAction,
} from './Action';
import { CheckCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const AllPlans = () => {
  const cacheAllPlans = localStorage.getItem('allPlans');
  const navigate = useNavigate();
  const [allPlans, setAllPlans] = useState(
    cacheAllPlans ? JSON.parse(cacheAllPlans) : []
  );

  const [activeSubscription, setActiveSubscription] = useState({});

  const getMySubscription = async () => {
    await phonePeGetActiveSubscriptionAction().then(
      ({ error, message, data }) => {
        if (!error) {
          console.log('data', data);
          setActiveSubscription(data);
        }
      }
    );
  };

  useEffect(() => {
    getMySubscription();
  }, []);

  const verifySubscription = async (product_id) => {
    await phonePeVerifyTransactionAction({ product_id })
      .then(({ error, message }) => {
        if (!error) {
          notification.success({ message });
        } else {
          notification.error({ message });
        }
      })
      .catch((err) => console.log('err', err));

    navigate('/trade/all-plans', { replace: true });
  };

  useEffect(() => {
    const url = window.location.href;
    const regex = /subscription_product_id=(\d+)/;
    const match = url.match(regex);

    var product_id = match ? match[1] : null;

    if (product_id) {
      verifySubscription(product_id);
    } else {
      console.log('Subscription Product ID not found');
    }
  }, []);


  const getAllPlans = () => {
    getPhonePeSubscriptionProductAction().then(({ data, error, message }) => {
      if (!error) {
        console.log('data', data);
        setAllPlans(data);
        localStorage.setItem('allPlans', JSON.stringify(data));
      } else {
        notification.error({ message });
      }
    });
  };

  useEffect(() => {
    getAllPlans();
  }, []);

  const onSubscribe = async (sub_id) => {
    await createPhonePeSubscriptionAction({
      payload: {
        subscription_product_id: sub_id,
      },
    }).then(({ data, error, message }) => {
      if (!error) {
        console.log('data', data);
        window.location.href = data;
      } else {
        notification.error({ message });
      }
    });
  };

  return (
    <div className='plan-main-ctr'>
      <h2 className='ctr-header all-pricing-header'>
        We’ve got a pricing plan that’s perfect for you
      </h2>
      <div className='card_holder pricing-holder'>
        {[
          {
            subscription_product_id: null,
            stripe_product_id: null,
            billing_cycle: 'FREE',
            stripe_price_id: null,
            price: null,
            currency: 2,
          },
          ...allPlans,
        ].map((plan) => {
          const freePlan = plan.billing_cycle === 'FREE';
          const activePlan =
            activeSubscription &&
            activeSubscription.subscription_product_id ===
              plan.subscription_product_id;

          return (
            <div className={`card-ctr mobile-card-ctr`}>
              <p className='plan-type'>
                {getKeyByValue(billingCycleEnums, plan.billing_cycle)}
              </p>
              <p className='plan-price'>
                {freePlan
                  ? 'Free For All Users'
                  : `${
                      plan.currency === currencyEnums['INR'] ? '₹' : '₹'
                    } ${''} ${
                      freePlan
                        ? ''
                        : plan.billing_cycle === billingCycleEnums['MONTHLY']
                        ? '800 / m'
                        : '8000 / yr'
                    }`}
              </p>

              <div className='plan_benefits'>
                {plansBenefits[plan.billing_cycle].map((planBenefit, index) => (
                  <div
                    className={`benefit ${
                      index === 3 &&
                      plan.billing_cycle !== billingCycleEnums['YEARLY'] &&
                      'not-allowed'
                    }`}
                  >
                    <CheckCircleFilled className='icon' />
                    <p>{planBenefit}</p>
                  </div>
                ))}
              </div>
              <a
                className='plan_btn'
                onClick={() => {
                  if (activePlan || freePlan) return;
                  onSubscribe(plan.subscription_product_id);
                }}
              >
                {/* <Spin className='spin-loading' /> */}
                {activePlan
                  ? 'Active Plan'
                  : freePlan
                  ? 'Free Plan'
                  : 'Go Premium'}
              </a>
              {/* {activePlan && (
                <p className='go-premium'>
                  Change Of Mind?{' '}
                  <span onClick={cancelSubscription}>Cancel Now</span>{' '}
                </p>
              )} */}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AllPlans;
