import React, { useEffect, useRef } from 'react';
import { widget } from '../../charting_library';
import DATAFEED from './datafeed';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { pickMyTradeHelpMail } from '../../Utils/settings';

function getLanguageFromURL() {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export const ChartContainer = () => {
  const loginUser = JSON.parse(localStorage.getItem('logInUser'));

  const navigate = useNavigate();
  const dispatch = useDispatch();

  let widgetRef = useRef();
  const chartContainerRef = useRef();

  const {
    turningPoints,
    chartData,
    scrollPosition,
    angleCombinations,
    collapseOppened,
    uniqueDates,
    searchParams,
    // candleMarkers
  } = useSelector((state) => state.dashboard);

  const { candleMarkers, indicatorData, selectedChartAngles } = useSelector(
    (state) => state.planet
  );

  const defaultProps = {
    symbol: searchParams.symbol ? searchParams.symbol : '^NSEI',
    interval: 'D',
    datafeedUrl: 'https://demo_feed.tradingview.com',
    libraryPath: '/charting_library/',
    chartsStorageUrl: 'https://saveload.tradingview.com',
    chartsStorageApiVersion: '1.1',
    clientId: 'tradingview.com',
    userId: 'public_user_id',
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
  };

  useEffect(() => {
    // if (chartData.length === 0) return;
    const widgetOptions = {
      symbol: defaultProps.symbol,
      // BEWARE: no trailing slash is expected in feed URL
      datafeed: DATAFEED,
      interval: defaultProps.interval,
      container: chartContainerRef.current,
      library_path: defaultProps.libraryPath,
      timezone: 'Asia/Kolkata',

      locale: getLanguageFromURL() || 'en',
      disabled_features: ['use_localstorage_for_settings'],
      enabled_features: ['study_templates'],
      charts_storage_url: defaultProps.chartsStorageUrl,
      charts_storage_api_version: defaultProps.chartsStorageApiVersion,
      client_id: defaultProps.clientId,
      user_id: defaultProps.userId,
      fullscreen: defaultProps.fullscreen,
      autosize: defaultProps.autosize,
      studies_overrides: defaultProps.studiesOverrides,
      // toolbar_bg: '#ebf5fb',
      // drawings_access: {
      //   type: 'white',
      //   tools: [
      //     {
      //       name: 'Trend Line',
      //       // grayed: true
      //     },
      //     {
      //       name: 'Vertical Line',
      //       // grayed: true
      //     },
      //     {
      //       name: 'Circle',
      //       // grayed: true
      //     },
      //   ],
      // },
      numeric_formatting: { decimal_sign: '.' },
      // theme: "dark",
      // disabled_features: ["header_widget", "legend_widget", "timeframes_toolbar", "two_character_bar_marks_labels"],
      disabled_features: [
        'edit_buttons_in_legend',
        'format_button_in_legend',
        'show_hide_button_in_legend',
        'context_menus',

        // header Settings
        'header_chart_type',
        'header_compare',
        'header_fullscreen_button',
        // 'header_indicators',
        'header_resolutions',
        'header_screenshot',
        'header_settings',
        // 'header_symbol_search',
        'header_undo_redo',

        // 'legend_widget',
        'timeframes_toolbar',
        'two_character_bar_marks_labels',
      ],
      enabled_features: ['two_character_bar_marks_labels'],
      overrides: {
        'paneProperties.background': '#ebf5fb',
        // 'paneProperties.backgroundType': 'gradient',
        // 'paneProperties.backgroundGradientStartColor': '#ebf5fb',
        // 'paneProperties.backgroundGradientEndColor': '#7e98b9',
        'paneProperties.vertGridProperties.color': '#3f5885',
        'paneProperties.vertGridProperties.style': 1,
        'paneProperties.horzGridProperties.color': '#3f5885',
        'paneProperties.horzGridProperties.style': 1,

        'paneProperties.separatorColor': '#3f5885',

        'crossHairProperties.color': '#FF6026',
      },

      // numeric_formatting: {
      //   decimal_sign: '.',
      // },
      // priceFormat: {
      //   type: "price",
      //   precision: 6,
      //   minMove: 0.000001,
      // },
      //
      custom_indicators_getter: function (PineJS) {
        const indicators = Object.keys(indicatorData).map((item, index) => {
          const vv = indicatorData[item];
          return {
            name: `${item}`,
            metainfo: {
              _metainfoVersion: 51,
              id: `${index}_${item}@tv-basicstudies-${index}`,
              description: `${item}`,
              shortDescription: `${index + 1} ${item}`,
              is_price_study: false,
              isCustomIndicator: true,
              plots: [
                {
                  id: 'plot_0',
                  type: 'line',
                },
              ],
              defaults: {
                // ...
                styles: {
                  plot_0: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,

                    // PLOTTYPE BELOW
                    // 0- line
                    // 1- histogram
                    // 3- cross
                    // 4- area
                    // 5- columns
                    // 6- circles
                    // 7- line with breaks
                    // 8- area with breaks
                    // 9- step line
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: vv[0].color,
                  },
                },
              },
              styles: {
                plot_0: {
                  title: 'Plot',
                  histogramBase: 0,
                  joinPoints: false,
                },
              },
              // styles: {
              //   plot_0: {
              //     title: 'Equity value',
              //     histogramBase: 0,
              //   },
              // },
              inputs: [],
              format: {
                type: 'price',
                precision: 4,
              },
            },
            constructor: function () {
              // this.init = function (context, inputCallback) {
              //   var symbol = '#EQUITY';
              //   var period = PineJS.Std.period(this._context);
              //   context.new_sym(symbol, period);
              // };

              function binarySearch(data, targetDate) {
                let left = 0;
                let right = data.length - 1;

                while (left <= right) {
                  const mid = Math.floor((left + right) / 2);
                  const midDate = data[mid].date;

                  if (midDate === targetDate) {
                    return data[mid];
                  } else if (midDate < targetDate) {
                    left = mid + 1;
                  } else {
                    right = mid - 1;
                  }
                }

                // If the target date is not found, you can handle it accordingly (return null, throw an error, etc.)
                return null;
              }

              this.main = function (context, inputCallback) {
                const targetDate = dayjs(context.symbol.time).format(
                  'YYYY-MM-DD'
                );
                const result = binarySearch(vv, targetDate);
                if (!result) return 0;
                else return result.value;

                // return result.value;
              };
            },
          };
        });

        return Promise.resolve(indicators);
      },
    };

    const tvWidget = new widget(widgetOptions);

    tvWidget.onChartReady(() => {
      widgetRef.current = tvWidget.activeChart();
      tvWidget.addCustomCSSFile('./chartHeader.css');

      tvWidget.activeChart().setVisibleRange({
        from: dayjs().subtract(200, 'day').unix(),
        to: dayjs().add(20, 'day').unix(),
      });

      // tvWidget.headerReady().then(function () {
      //   let button = tvWidget.createButton();
      //   button.setAttribute('title', 'Combinations');
      //   button.addEventListener('click', function () {
      //     navigate('/trade/combinations');
      //   });
      //   button.textContent = 'Combinations';
      // });

      tvWidget.createDropdown({
        title: 'Contact Us',
        tooltip: 'Contact Us',
        align: 'right',
        items: [
          {
            title: pickMyTradeHelpMail,
            onSelect: () => {
              window.location.href = `mailto:${pickMyTradeHelpMail}`;
            },
          },
        ],
      });

      tvWidget.createDropdown({
        title: loginUser?.name || 'User',
        tooltip: 'User Action',
        align: 'right',
        items: [
          {
            title: 'Pricing',
            onSelect: () => {
              navigate('/trade/all-plans');
            },
          },
          {
            title: 'Reset Password',
            onSelect: () => {
              navigate('/auth/reset_password');
            },
          },
          {
            title: 'Logout',
            onSelect: () => {
              localStorage.clear();
              dispatch({ type: 'USER_LOGOUT' });
              navigate('/auth/login');
            },
          },
        ],
        icon: `<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>`,
      });

      // tvWidget.activeChart().createMultipointShape(
      //   [
      //     { time: dayjs().add(1, 'day').unix(), price: 22123.65 },
      //     { time: dayjs('2024-02-23').add(1, 'day').unix(), price: 22212.7 },
      //   ],
      //   {
      //     shape: 'circle',
      //     lock: true,
      //   }
      // );
    });

    return () => {
      tvWidget.remove();
    };
  }, [indicatorData]);

  useEffect(() => {
    if (collapseOppened.length === 0 || collapseOppened[0] === '1') {
      return widgetRef?.current?.refreshMarks();
    } else {
      widgetRef?.current?.clearMarks();
      widgetRef?.current?.refreshMarks();
    }
  }, [collapseOppened]);

  useEffect(() => {
    widgetRef?.current?.clearMarks();
    widgetRef?.current?.refreshMarks();
  }, [candleMarkers, uniqueDates]);

  useEffect(() => {
    widgetRef?.current?.removeAllStudies();
    Object.keys(selectedChartAngles).forEach((key) => {
      widgetRef?.current?.createStudy(key);
    });
  }, [selectedChartAngles]);

  return <div ref={chartContainerRef} className={'dashboard_chart'} />;
};
