import React from 'react';
import {
  pickMyTradeHelpMail,
  tradingViewLink,
  tradingViewLogo,
} from '../../Utils/settings';

export default function TradingView() {
  return (
    <div className='tradingview_ctr'>
      <img className='img' src={tradingViewLogo} />
      Charts powered by{' '}
      <a href={tradingViewLink} target='_blank' className='link'>
        Tradingview
      </a>
      <p style={{ marginLeft: 10, marginRight: 10 }}>|</p>
      Having Trouble?{' '}
      <a
        href={`mailto:${pickMyTradeHelpMail}`}
        target='_blank'
        className='link'
      >
        Contact Us
      </a>
    </div>
  );
}
