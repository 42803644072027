import {
  Button,
  Calendar,
  Card,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import React, { useEffect, useState } from 'react';
import './events.css';
import moment from 'moment';
import dayjs from 'dayjs';
import {
  CloseOutlined,
  EyeOutlined,
  FilterOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  setScrollPosition,
  setTurningPoints,
} from '../../redux/dashboardSlice/dashSlice';
import {
  setCalendarData,
  setEventData,
  setinitialPayload,
  setselectedEventList,
} from '../../redux/eventSlice/eventSlice';
import {
  combinationModeOptions,
  dateFormat,
  getRandomColor,
  goToPositionOnChart,
  neighboursOptions,
  validateNumber,
} from '../../Utils/settings';
import { useNavigate } from 'react-router-dom';
import { getCalenderEventsAction } from './Action';

export default function Events() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [filterForm] = Form.useForm();

  const [openModal, setOpenModal] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [modalData, setModalData] = useState({ date: null, data: [] });

  const [loading, setLoading] = useState(false);

  const { chartData, searchParams } = useSelector((state) => state.dashboard);
  const {
    calendarData,
    initialPayload: reduxInitialPayload,
    selectedEventList,
  } = useSelector((state) => state.event);

  const getEventsData = async () => {
    const payload = [
      {
        ...reduxInitialPayload,
        month: reduxInitialPayload.month + 1,
        exchange_name: searchParams.exchange_name,
        symbol: searchParams.symbol,
      },
    ];
    setLoading(true);
    await getCalenderEventsAction({ payload })
      .then(({ error, message, data }) => {
        if (!error) {
          // setModalData({});
          dispatch(setCalendarData(data));
        } else notification.error({ message });
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  useEffect(() => {
    getEventsData();
    dispatch(setTurningPoints([]));
    dispatch(setEventData({}));
  }, [reduxInitialPayload]);

  const getListData = (value) => {
    const keys = Object.keys(calendarData);
    let listData;

    keys.forEach((key) => {
      if (key === dayjs(value).format('YYYY-MM-DD'))
        return (listData = calendarData[key]);
    });
    return listData || [];
  };

  const cellDataRender = (current, info) => {
    if (info.type === 'date') {
      const listData = getListData(current);
      return listData;
    }
  };

  const onDateClicked = ({ date, data }) => {
    if (data.length === 0) return;

    setOpenModal(true);
    setModalData({
      date,
      data,
    });
  };

  function onCancel() {
    setOpenModal(false);
    setModalData({});
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Pattern Name',
      dataIndex: 'pattern_name',
      key: 'pattern_name',
    },
    {
      title: 'Pattern Values',
      dataIndex: 'pattern_values',
      key: 'pattern_values',
    },
    {
      title: 'Success Rate',
      dataIndex: 'success_rate',
      key: 'success_rate',
      render: (values) => values || '-',
    },
    {
      title: 'Day Zero Success Rate',
      dataIndex: 'day_zero_success_rate',
      key: 'day_zero_success_rate',
      render: (values) => values || '-',
    },
    {
      title: 'Total Event',
      dataIndex: 'total_event',
      key: 'total_event',
    },
    {
      title: 'Positive Event',
      dataIndex: 'positive_event',
      key: 'positive_event',
    },
    {
      title: 'Negative Event',
      dataIndex: 'negative_event',
      key: 'negative_event',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        const selected =
          selectedEventList.filter((item) => item.key === record.key).length >
          0;

        if (!selected) {
          return (
            <Tooltip title='Add To List'>
              <Button
                type='primary'
                onClick={() => {
                  dispatch(setselectedEventList({ type: 'ADD', data: record }));
                }}
                icon={<PlusOutlined />}
              />
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title='Remove From List'>
              <Button
                danger
                type='primary'
                onClick={() => {
                  dispatch(
                    setselectedEventList({ type: 'REMOVE', data: record })
                  );
                }}
                icon={<CloseOutlined />}
              />
            </Tooltip>
          );
        }
      },
    },
  ].filter((col) => {
    if (modalData.date) {
      return col.dataIndex !== 'date';
    } else return col;
  });

  const EventModal = (
    <Modal
      open={openModal}
      onCancel={onCancel}
      centered
      className='event_modal'
      footer={
        !modalData.date && (
          <Button
            onClick={() => {
              dispatch(setEventData(selectedEventList));

              // const position = goToPositionOnChart({
              //   date: record.date,
              //   data: chartData,
              // });
              // if (position) dispatch(setScrollPosition(position));

              const tp = selectedEventList.map((item) => ({
                time: item.date,
                position: 'aboveBar',
                color: '#0F6CBD',
                shape: 'arrowDown',
                text: moment(item.date).format('DD MMM YYYY'),
              }));

              // const tp = [
              //   {
              //     time: record.date,
              //     position: 'aboveBar',
              //     color: '#0F6CBD',
              //     shape: 'arrowDown',
              //     text: moment(record.date).format('DD MMM YYYY'),
              //   },
              // ];

              dispatch(setTurningPoints(tp));

              navigate('/event_details');
            }}
            type='primary'
          >
            Show On Chart
          </Button>
        )
      }
    >
      <Card
        title={
          <div className='card_header_title'>
            {modalData.date
              ? dayjs(modalData?.date).format('DD MMMM YYYY')
              : 'Selected Events'}
            {/* {dayjs(modalData?.date).format('DD MMMM YYYY')} */}
            {/* <Button className='select_btn'>Select Date</Button> */}
            {/* <Typography.Text className='select_btn'>Select Date</Typography.Text> */}
          </div>
        }
        style={{
          marginTop: 25,
          marginBottom: 20,
        }}
      >
        <Table
          columns={columns}
          dataSource={modalData.data}
          pagination={false}
          bordered
        />
      </Card>
    </Modal>
  );

  return (
    <div className='event_calendar-ctr'>
      {EventModal}
      <Calendar
        fullCellRender={(current, info) => {
          const today =
            dayjs(info.today).format('DD') === dayjs(current).format('DD');

          const data = cellDataRender(current, info);
          return (
            <div
              className={`date-cell ${today && 'today'}`}
              onClick={() =>
                onDateClicked({
                  date: current,
                  data: data.map((vv) => ({
                    ...vv,
                    date: dayjs(current).format(dateFormat),
                    key: `${dayjs(current).format(dateFormat)}_${
                      vv.pattern_name
                    }_${vv.pattern_values}_${vv.success_rate}_${
                      vv.positive_event
                    }_${vv.total_event}_${vv.day_zero_success_rate}_${
                      vv.negative_event
                    }`,
                  })),
                })
              }
            >
              <p>{moment(new Date(current)).format('DD')}</p>
              <div className='date_cell_list-ctr'>
                {data.map((item, index) => {
                  if (index > 1) return;
                  return (
                    <div className='badge'>
                      <div
                        className='dot'
                        style={{
                          backgroundColor: getRandomColor(),
                        }}
                      />
                      <p className='body_text'>{item.pattern_name}</p>
                    </div>
                  );
                })}
                {data.length > 2 ? (
                  <p className='show_more'>View All...</p>
                ) : data.length > 0 ? (
                  <p className='show_more'>View Details</p>
                ) : null}
              </div>
            </div>
            // <Spin tip='Loading Events...' spinning={loading}>
            // </Spin>
          );
        }}
        fullscreen={false}
        className='event-calendar'
        headerRender={({ value, onChange }) => {
          function onFilterModalClose() {
            setOpenFilterModal(false);
            filterForm.resetFields();
          }

          function onFilterModalFinished(values) {
            dispatch(setselectedEventList({ data: {}, type: '' }));
            dispatch(setinitialPayload(values));
            // setInitialPayload((prev) => ({ ...prev, ...values }));
            onChange(dayjs().clone().month(values.month).year(values.year));
            setOpenFilterModal(false);
          }

          const start = 0;
          const end = 12;
          const monthOptions = [];

          let current = value.clone();
          const localeData = value.localeData();
          const months = [];
          for (let i = 0; i < 12; i++) {
            current = current.month(i);
            months.push(localeData.monthsShort(current));
          }

          for (let i = start; i < end; i++) {
            monthOptions.push(
              <Select.Option key={i} value={i} className='month-item'>
                {months[i]}
              </Select.Option>
            );
          }
          const currentYear = Number(dayjs().format('YYYY'));
          const options = [];
          for (let i = currentYear - 23; i < currentYear + 10; i += 1) {
            options.push(
              <Select.Option key={i} value={i} className='year-item'>
                {i}
              </Select.Option>
            );
          }
          return (
            <div style={{ padding: 8, paddingTop: 10 }}>
              <Modal
                open={openFilterModal}
                centered
                footer={null}
                closable={false}
              >
                <Form
                  labelCol={{ span: 8 }}
                  className='calander_form'
                  form={filterForm}
                  onFinish={onFilterModalFinished}
                  initialValues={reduxInitialPayload}
                >
                  <Form.Item
                    label='Year'
                    name={'year'}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select>{options}</Select>
                  </Form.Item>

                  <Form.Item
                    label='Month'
                    name={'month'}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select>{monthOptions}</Select>
                  </Form.Item>

                  <Form.Item
                    label='City'
                    name={'city'}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter a City.',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  {/* <Form.Item
                    label='Exchange Name'
                    name={'exchange_name'}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Exchange Name.',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label='symbol'
                    name={'symbol'}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter a Symbol.',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item> */}

                  <Form.Item
                    label='Combination Count'
                    name={'combination_count'}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter a number.',
                      },
                      { validator: validateNumber },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label='Success Rate'
                    name={'success_rate'}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter a number.',
                      },
                      { validator: validateNumber },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name={'total_count'}
                    label='Total Count'
                    rules={[
                      {
                        required: true,
                        message: 'Please enter a number.',
                      },
                      { validator: validateNumber },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label='Neighbour'
                    name={'neighbour'}
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder='Select Neighbour'
                      style={{
                        width: '100%',
                      }}
                      options={neighboursOptions}
                    />
                  </Form.Item>

                  <Form.Item
                    label='Mode'
                    name={'mode'}
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder='Select Mode'
                      style={{
                        width: '100%',
                      }}
                      options={combinationModeOptions}
                    />
                  </Form.Item>

                  <div className='filter_form_buttons-ctr'>
                    <Button
                      danger
                      className='form-buttons'
                      onClick={onFilterModalClose}
                    >
                      Cancel
                    </Button>
                    <Form.Item className='form-item'>
                      <Button
                        htmlType='submit'
                        type='primary'
                        className='form-buttons'
                      >
                        Apply
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </Modal>
              <Row className='header_row'>
                <Typography.Title style={{ margin: 0 }} level={4}>
                  {dayjs()
                    .month(reduxInitialPayload.month)
                    .year(reduxInitialPayload.year)
                    .format('MMM YYYY')}
                </Typography.Title>
                <div className='spinner_ctr'>
                  <Spin spinning={loading} tip='Loading' />
                  {loading && (
                    <Typography.Text type='secondary' className='text_loading'>
                      Loading Events...
                    </Typography.Text>
                  )}
                </div>

                <div>
                  <FilterOutlined
                    onClick={() => setOpenFilterModal(true)}
                    className='calendar_filter_icon'
                  />
                  <Button
                    onClick={() =>
                      onDateClicked({
                        date: null,
                        data: selectedEventList,
                      })
                    }
                    type='primary'
                  >
                    View List
                  </Button>
                </div>
              </Row>
            </div>
          );
        }}
      />
    </div>
  );
}
