import axios from 'axios';
import { getToken } from '../../Utils/settings';
import {
  downloadCombinationsUrl,
  getAngleColumnUrl,
  getAngleColumnValuesUrl,
  getPlanetAnglesUrl,
} from '../Api';
import fileDownload from 'js-file-download';

export const getPlanetAnglesAction = ({ payload, page_Number = 1 }) => {
  const tokenHeader = getToken();

  return new Promise((resolve, reject) => {
    axios
      .post(
        `${getPlanetAnglesUrl}?page=${page_Number}&page_size=10000`,
        payload,
        tokenHeader
      )
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};

export const downloadCombinationsAction = ({
  payload,
  currentReq,
  setCurrentReq,
}) => {
  const tokenHeader = getToken();

  const controller = new AbortController();

  if (currentReq) currentReq.abort();

  setCurrentReq(controller);

  return new Promise((resolve, reject) => {
    axios
      .post(downloadCombinationsUrl, payload, {
        ...tokenHeader,
        signal: controller.signal,
        responseType: 'blob',
      })
      .then((response) => {
        fileDownload(response.data, 'download.zip');
        // resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};

export const getAngleColumnAction = () => {
  const tokenHeader = getToken();

  return new Promise((resolve, reject) => {
    axios
      .get(getAngleColumnUrl, tokenHeader)
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};

export const getAngleColumnValuesAction = ({ payload }) => {
  const tokenHeader = getToken();

  return new Promise((resolve, reject) => {
    axios
      .post(getAngleColumnValuesUrl, payload, {
        tokenHeader,
      })
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};
