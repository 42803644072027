import axios from 'axios';
import {
  addUserUrl,
  getMeUrl,
  loginUrl,
  requestPasswordResetUrl,
  setNewPasswordUrl,
  verifyEmailUrl,
} from '../Api';
import { getToken } from '../../../Utils/settings';

export const loginAction = ({ payload }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(loginUrl, payload)
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};

export const getMeAction = () => {
  const tokenHeader = getToken();

  return new Promise((resolve, reject) => {
    axios
      .get(getMeUrl, tokenHeader)
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};

export const addUserAction = ({ payload }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(addUserUrl, payload)
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};

export const requestPasswordResetAction = ({ email }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${requestPasswordResetUrl}?email=${email}`)
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};

export const verifyEmailAction = ({ email_token }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${verifyEmailUrl}?email_token=${email_token}`)
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};

export const setNewPasswordAction = ({ payload }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(setNewPasswordUrl, payload)
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};
