import { Modal } from 'antd';
import React from 'react';

import './terms.css';

const TermsAndServices = ({ open, closeModal = () => {} }) => {
  return (
    <div className='privacypolicy-ctr'>
      <h2 className='ctr-header policy-header'>Terms of Service</h2>
      From everyone at PickMyTrade, thank you for using our product.
      <br />
      When we say “PickMyTrade”, “we”, “our”, or “us” in this document, we are
      referring to PickMyTrade LLC, a company registered in the State of
      Delaware (USA).
      <br />
      When we say “Service”, or “Services”, we mean any product created and
      maintained by PickMyTrade, whether delivered within a web browser, desktop
      application, mobile application, or another format. <br />
      We may update these Terms of Service in the future. Typically, these
      changes have been to clarify some of these terms by linking to an expanded
      related policy.
      <br />
      When you use our Services, now or in the future, you are agreeing to the
      latest Terms of Service. That’s true for any of our existing and future
      products and all features that we add to our Services over time. There may
      be times where we do not exercise or enforce any right or provision of the
      Terms of Service; in doing so, we are not waiving that right or provision.
      These terms do contain a limitation of our liability.
      <br />
      If you violate any of the terms, we may terminate your account. That’s a
      broad statement and it means you need to place a lot of trust in us. We do
      our best to deserve that trust by being open about who we are, how we
      work, and keeping an open door to your feedback.
      <br />
      <p className='sub-header-modal'>Account terms</p>
      <ul>
        <li>
          You are responsible for maintaining the security of your account and
          password, or social login. PickMyTrade or its officers, directors,
          employees, consultants, or contractors cannot and will not be liable
          for any loss or damage from your failure to comply with this security
          obligation.
        </li>
        <li>
          You are responsible for the activity that occurs under your account.
          That includes information uploaded or connections created by you or
          others who either: (a) have access to your login credentials (which we
          strongly discourage); or (b) have been given third-party access by you
          to your account.
        </li>
      </ul>
      When you use the Services you warrant that you will comply with all
      applicable laws and also acknowledge that you may not:
      <ul>
        <li>
          Make any unauthorized use of the Services, or create user accounts by
          automated means or under false pretenses.
        </li>
        <li>
          Upload an unreasonable number of transactions or documents for the
          purpose of disrupting the Services.
        </li>
        <li>
          Abuse any offer, promotion, or discount made available from time to
          time to users including, but not limited to, creating and inviting
          fictitious user accounts.
        </li>
        <li>
          Circumvent, disable, or otherwise interfere with security-related
          features of the Services.
        </li>
        <li>Engage in unauthorized framing of or linking to the Services.</li>
        <li>
          Trick, defraud, or mislead us and other users, especially in any
          attempt to learn sensitive account information such as user passwords
          and personal information.
        </li>
        <li>
          Make improper use of our Services, including our support services or
          submit false reports of abuse or misconduct.
        </li>
        <li>
          Engage in any automated use of the Services, such as using scripts to
          send comments or messages, or using any data mining, robots, or
          similar data gathering and extraction tools.
        </li>
        <li>
          Interfere with, disrupt, or create an undue burden on the Services or
          the networks or the Services connected.
        </li>
        <li>
          Attempt to impersonate another user or person or use the login
          credentials of another user.
        </li>
        <li>
          Use the Services as part of any effort to compete with us or otherwise
          use the Services for any revenue-generating endeavor or commercial
          enterprise.
        </li>
        <li>
          Decipher, decompile, disassemble, or reverse engineer any of the
          software comprising or in any way making up a part of the Services,
          except as expressly permitted by applicable law.
        </li>
        <li>
          Attempt to bypass any measures of the Services designed to prevent or
          restrict access to the Services, or any portion of the Services.
        </li>
        <li>
          Harass, annoy, intimidate, or threaten any of our employees or agents
          engaged in providing any portion of the Services to you.
        </li>
        <li>Copy or adapt the Services’ software and computer code base.</li>
        <li>
          Upload or transmit (or attempt to upload or to transmit) viruses,
          Trojan horses, or other material, including excessive spamming
          (continuous posting of repetitive text), that interferes with any
          party’s uninterrupted use and enjoyment of the Services or modifies,
          impairs, disrupts, alters, or interferes with the use, features,
          functions, operation, or maintenance of the Services.
        </li>
        <li>
          Upload or transmit (or attempt to upload or to transmit) any material
          that acts as a passive or active information collection or
          transmission mechanism, including without limitation, clear graphics
          formats, 1×1 pixels, web bugs, cookies, or other similar devices
          (sometimes referred to as “spyware” or “passive collection mechanisms”
          or “pcms”).
        </li>
        <li>
          Except as may be the result of standard search engine or Internet
          browser usage, use, launch, develop, or distribute any automated
          system, including without limitation, any spider, robot, cheat
          utility, scraper, or offline reader that accesses the Services, or
          using or launching any unauthorized script or other software.
        </li>
        <li>
          Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
          Services.{' '}
        </li>
        <li>
          Reconstruct or attempt to discover any source code or algorithms of
          the Services, or any portion thereof, by any means whatsoever.
        </li>
        <li>Intercept any data not intended for you.</li>
      </ul>
      <p className='sub-header-modal'>Safe Harbor exception clause</p>
      Because we run a Bug Bounty Program, we don’t want researchers to be in
      fear of consequences because of their good faith attempts to comply with
      our Bug Bounty Program terms. To encourage research and coordinated
      disclosure of security vulnerabilities, we will not pursue civil or
      criminal action, or send notice to law enforcement for accidental or good
      faith violations of these Terms of Service. We consider security research
      and vulnerability disclosure activities conducted consistent with our Bug
      Bounty Program terms to be “authorized” conduct under the Computer Fraud
      and Abuse Act, the DMCA, and other applicable computer use laws such as
      Cal. Penal Code 502(c). We waive any potential DMCA claim against you for
      circumventing the technological measures we have used to protect the
      applications in our Bug Bounty Program’s scope.
      <br />
      Please understand that if your security research involves the networks,
      systems, information, applications, products, or services of a third party
      (which is not us), we cannot bind that third party, and they may pursue
      legal action or law enforcement notice. We cannot and do not authorize
      security research in the name of other entities, and cannot in any way
      offer to defend, indemnify, or otherwise protect you from any third party
      action based on your actions.
      <br />
      You are expected, as always, to comply with all laws applicable to you,
      and not to disrupt or compromise any data beyond what this Bug Bounty
      Program permits.
      <br />
      Please contact us before engaging in conduct that may be inconsistent with
      or unaddressed by this policy. We reserve the sole right to make the
      determination of whether a violation of this policy is accidental or in
      good faith, and proactive contact to us before engaging in any action is a
      significant factor in that decision. If in doubt, ask us first!
      <br />
      <p className='sub-header-modal'>Payment, refunds, and plan changes</p>
      <ul>
        <li>
          If you are using a free version of the Services, it is really free: we
          do not ask you for your credit card and — just like for users who pay
          for our Services — we do not sell your data.
        </li>
        <li>
          For paid Services that offer a free trial, we explain the length of
          trial when you sign up. After the trial period, you need to pay in
          advance to keep using the Service. If you do not pay, we downgrade
          your account to a free account.
        </li>
        <li>
          If you are upgrading from a free plan to a paid plan, we will charge
          your card immediately and your billing cycle starts on the day of
          upgrade.
        </li>
        <li>
          All fees are inclusive of all taxes, levies, or duties imposed by
          taxing authorities. Where required, we will collect those taxes on
          behalf of the taxing authority and remit those taxes to taxing
          authorities.
        </li>
      </ul>
      We process refunds on a case-by-case basis in 15 days. If you’re ever unhappy with
      our Services for any reason, just contact our support team and we’ll take
      care of you.
      <br />
      <p className='sub-header-modal'>Cancellation and deletion</p>
      <ul>
        <li>
          You are solely responsible for properly canceling and deleting your
          account. An email request to cancel your account is not considered
          cancellation. If you need help canceling your account, you can always
          contact our Support team.
        </li>
        <li>
          All of your information and data will be inaccessible from the
          Services immediately upon cancellation. We might have the information
          in a temporary backup for a few weeks but we cannot recover this
          information once it has been permanently deleted.
        </li>
        <li>
          We have the right to suspend or terminate your account and refuse any
          and all current or future use of our Services for any reason at any
          time. Suspension means you and any other users on your account will
          not be able to access the account or any content in the account.
          Termination will furthermore result in the deletion of your account or
          your access to your account, and the forfeiture and relinquishment of
          all content in your account. We also reserve the right to refuse the
          use of the Services to anyone for any reason at any time. We have this
          clause because statistically speaking, out of the hundreds of
          thousands of accounts on our Services, there is at least one doing
          something nefarious. There are some things we staunchly stand against
          and this clause is how we exercise that stance.
        </li>
        <li>
          Verbal, physical, written or other abuse (including threats of abuse
          or retribution) of an PickMyTrade employee, officer, or agent may
          result in immediate account termination.
        </li>
      </ul>
      <p className='sub-header-modal'>
        Modifications to the service and prices
      </p>
      <ul>
        <li>
          Sometimes it becomes technically impossible to continue a feature or
          we redesign a part of our Services because we think it could be better
          or we decide to close new signups of a product. We reserve the right
          at any time to modify or discontinue, temporarily or permanently, any
          part of our Services with or without notice.
        </li>
        <li>
          Most of our Services are free, but some are not, and sometimes we may
          change the pricing structure. When we do that, we may choose to change
          the prices for existing users. If we do so, we will give at least 30
          days notice and will notify you via the email address on record. We
          may also post a notice about changes on our websites or the affected
          Services themselves.
        </li>
      </ul>
      <p className='sub-header-modal'>Uptime, security, and privacy</p>
      <ul>
        <li>
          Your use of the Services is at your sole risk. We provide these
          Services on an “as is” and “as available” basis.
        </li>
        <li>
          We take many measures to protect and secure your data through backups,
          redundancies, and encryption. We enforce encryption for data
          transmission from the public Internet. Please refer to our Security
          Overview for full details.
        </li>
        <li>
          When you use our Services, you entrust us with your data. We take that
          trust to heart. You agree that PickMyTrade may process your data as
          described in our Privacy Policy and for no other purpose. We as humans
          can access your data for the following reasons:
        </li>
        <li>
          To help you with support requests you make: we’ll ask for express
          consent before accessing your account.
        </li>
        <li>
          To safeguard PickMyTrade: we’ll look at logs and metadata as part of
          our work to ensure the security of your data and the Services as a
          whole.
        </li>
        <li>
          We use third party vendors and hosting partners to provide the
          necessary hardware, software, networking, storage, and related
          technology required to run the Services.
        </li>
        <li>
          We process any data you share with us only for the purpose you signed
          up for and as described in these Terms of Service. We do not retain,
          use, disclose, or sell any of that information for any other
          commercial purposes unless we have your explicit permission.
        </li>
        <li>
          These Service Terms incorporate the PickMyTrade Data Processing
          Addendum (“DPA”), when the General Data Protection regulation (“GDPR”)
          applies to your use of PickMyTrade Services to process Customer Data
          as defined in the DPA.
        </li>
      </ul>
      <p className='sub-header-modal'>Copyright and content ownership</p>
      <ul>
        <li>
          We claim no intellectual property rights over the information and data
          you provide to the Services. All information and data uploaded remain
          yours.
        </li>
        <li>
          The names, look, and feel of the Services are copyright© to
          PickMyTrade. All rights reserved. You may not duplicate, copy, or
          reuse any portion of the HTML, CSS, JavaScript, or visual design
          elements without express written permission from PickMyTrade. You must
          request permission to use PickMyTrade logo or any Service logos for
          promotional purposes. Please email us to request to use logos. We
          reserve the right to rescind this permission if you violate these
          Terms of Service.
        </li>
        <li>
          You agree not to reproduce, duplicate, copy, sell, resell, or exploit
          any portion of the Services, use of the Services, or access to the
          Services without the express written permission by PickMyTrade.
        </li>
        <li>
          You must not modify another website so as to falsely imply that it is
          associated with the Services or PickMyTrade.
        </li>
      </ul>
      <p className='sub-header-modal'>Features and bugs</p>
      We design our Services with care, based on our own experience and the
      experiences of users who share their time and feedback. However, there is
      no such thing as a service that pleases everybody. We make no guarantees
      that our Services will meet your specific requirements or expectations. We
      also test all of our features extensively before making them available. As
      with any software, our Services inevitably have some bugs. We track the
      bugs reported to us and work through priority ones, especially any related
      to security or privacy. Not all reported bugs will get fixed and we don’t
      guarantee completely error-free Services.
      <p className='sub-header-modal'>Liability</p>
      We mention liability throughout these Terms but to put it all in one
      section:
      <br />
      You expressly understand and agree that PickMyTrade or its officers,
      directors, employees, consultants or contractors shall not be liable, in
      law or in equity, to you or to any third party for any direct, indirect,
      incidental, lost profits, special, consequential, punitive or exemplary
      damages, including, but not limited to, damages for loss of profits,
      goodwill, use, data or other intangible losses (even if PickMyTrade or or
      its officers, directors, employees, consultants, or contractors have been
      advised of the possibility of such damages), resulting from: (i) the use
      or the inability to use the Services; (ii) the cost of procurement of
      substitute goods and services resulting from any goods, data, information
      or services purchased or obtained or messages received or transactions
      entered into through or from the Services; (iii) unauthorized access to or
      alteration of your transmissions or data; (iv) statements or conduct of
      any third party on the service; (v) or any other matter relating to this
      Terms of Service or the Services, whether as a breach of contract, tort
      (including negligence whether active or passive), or any other theory of
      liability. If you have a question about any of the Terms of Service,
      please contact our support team.
      
      <p className='end-text'>This website is managed by M/s Shalini Garg</p>
    </div>
  );
};

export default TermsAndServices;
