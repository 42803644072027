import { API_URL } from "../../../Utils/settings";

export const getAllPlansUrl = API_URL + '/stripe-subscriptions/subscription-products';

export const createSubscriptionUrl = API_URL + '/stripe-subscriptions/create'

export const getAllMySubscriptionsUrl = API_URL + '/stripe-subscriptions/get-all-subscriptions'

export const pauseSubscriptionUrl = API_URL + '/stripe-subscriptions/pause-subscription'

export const resumeSubscriptionUrl = API_URL + '/stripe-subscriptions/resume-subscription'

export const cancelSubscriptionUrl = API_URL + '/stripe-subscriptions/cancel-subscription'

// Phone Pe Payment
export const getPhonePeSubscriptionProductUrl = API_URL + '/phonepe-subscriptions/subscription-products'

export const createPhonePeSubscriptionUrl = API_URL + '/phonepe-subscriptions/create'

export const phonePeVerifyTransactionUrl = API_URL + '/phonepe-subscriptions/verify-transaction'

export const phonePeGetActiveSubscriptionUrl = API_URL + '/phonepe-subscriptions/get-active-subscription'