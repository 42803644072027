import {
  Button,
  Form,
  Input,
  Layout,
  Space,
  Typography,
  notification,
} from 'antd';
import React, { useState } from 'react';
import './auth.css';
import { useNavigate } from 'react-router-dom';
import { LoginOutlined, SmileOutlined } from '@ant-design/icons';
import { tradingViewLink, tradingViewLogo } from '../../Utils/settings';
import TradingView from '../../Components/TradingViewDownload';
import { addUserAction } from './Action';

export default function Register() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();

  const [loading, setloading] = useState(false);

  const onFinish = async (values) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Button
          type='default'
          size='small'
          onClick={() => {
            api.destroy();
            navigate('/auth/login', { replace: true });
          }}
        >
          Back To Login
        </Button>
        {/* <Button type='primary' size='small' onClick={() => api.destroy(key)}>
          Confirm
        </Button> */}
      </Space>
    );

    const payload = {
      email: values.email,
      name: values.username,
      password: values.confirm_password,
      phone_number: values.phone_number,
    };

    setloading(true);
    await addUserAction({ payload }).then(({ data, error, message }) => {
      if (!error) {
        api.open({
          message: (
            <Typography.Text type='success'>
              You have successfully registered
            </Typography.Text>
          ),
          description: (
            <Typography.Text type='secondary'>
              {`Congratulations! You have successfully registered. A verification link has been sent ${data.user}. kindly verify yourself to activate your account.`}
            </Typography.Text>
          ),
          btn,
          key,
          icon: <SmileOutlined />,
        });
      } else {
        notification.error({ message });
      }
    });
    setloading(false);
  };

  const onSignup = () => {
    navigate('/auth/login', { replace: true });
  };

  return (
    <section className='login-ctr'>
      {contextHolder}
      <div className='login-box register-box'>
        <h2 className='login_title'>Create New Account</h2>
        <p className='login_subtitle'>
          Enter Your details below to create your account and get started.
        </p>
        <Form
          form={form}
          className='login-form_ctr'
          onFinish={onFinish}
          autoComplete={false}
        >
          <p className='item_title'>Full Name</p>
          <Form.Item
            name={'username'}
            rules={[
              {
                required: true,
                message: 'Name is required!',
              },
              {
                validator: (_, value) => {
                  // Check if the entered value is a valid email address
                  const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

                  // If it's a valid email, reject the promise with an error message
                  if (isEmail) {
                    return Promise.reject(
                      'Username cannot be an email address!'
                    );
                  }

                  // If it's not an email, resolve the promise
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input placeholder='Your Name' className='form-item' />
          </Form.Item>

          <p className='item_title'>User Email</p>
          <Form.Item
            name={'email'}
            rules={[
              {
                type: 'email',
                message: 'Please enter a valid email address!',
              },
              {
                required: true,
                message: 'Email is required!',
              },
            ]}
          >
            <Input placeholder='Email' className='form-item' />
          </Form.Item>

          {/* <p className='item_title'>Phone Number</p>
          <Form.Item
            name={'phone_number'}
            rules={[
              {
                required: true,
                message: 'Please enter your phone number!',
              },
              {
                pattern: /^[0-9]{10}$/,
                message: 'Phone number must be exactly 10 numeric digits!',
              },
            ]}
          >
            <Input placeholder='Phone Number' className='form-item' />
          </Form.Item> */}

          <p className='item_title'>Password</p>
          <Form.Item
            name={'password'}
            rules={[
              {
                required: true,
                message: 'Password is required!',
              },
            ]}
          >
            <Input.Password placeholder='Password' className='form-item' />
          </Form.Item>

          <p className='item_title'>Confirm Password</p>
          <Form.Item
            name={'confirm_password'}
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Passwords do not match!');
                },
              }),
            ]}
          >
            <Input.Password
              placeholder='Confirm Password'
              className='form-item'
            />
          </Form.Item>

          <Form.Item>
            <Button
              loading={loading}
              icon={<LoginOutlined />}
              htmlType='submit'
              className='form-item form-item-btn '
            >
              Sign-Up
            </Button>
          </Form.Item>

          <p onClick={onSignup} className='signup-text'>
            Already have an Account?
            <span>Login</span>
          </p>
        </Form>

        <TradingView />
      </div>
    </section>
  );
}
