import React, { useState } from 'react';

import './pricing.css';
import { CheckCircleFilled } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';

const tabs = {
  FREE: 1,
  MONTHLY: 2,
  YEARLY: 3,
};

const Pricing = () => {
  const [activeTab, setActiveTab] = useState(tabs['FREE']);

  function GoPremiumBtn({ text = 'Try free for 14 days' }) {
    return (
      <>
        <Link className='plan_btn' target='_blank' to={'/auth/login'}>
          {text}
        </Link>

        <Link className='go-premium' target='_blank' to={'/auth/login'}>
          or skip trial and <span>pay now</span>{' '}
        </Link>
      </>
    );
  }

  const onChange = (key) => {
    setActiveTab(key);
  };

  return (
    <div className='pricing-ctr'>
      {/* <h2 className='ctr-header'>Why PickMyTrade Stands Out</h2> */}
      <h2 className='ctr-header'>
        We’ve got a pricing plan that’s perfect for you!
      </h2>
      <p className='ctr-subheader'>
        We believe PickMyTrade should be accessible for everyone
      </p>

      {/* <Tabs defaultActiveKey='1' items={items} onChange={onChange} /> */}
      <div className='tabs-container'>
        <div
          onClick={() => onChange(tabs['FREE'])}
          className={`${activeTab === tabs['FREE'] && 'active-tab'}`}
        >
          Free
        </div>
        <div
          onClick={() => onChange(tabs['MONTHLY'])}
          className={`${activeTab === tabs['MONTHLY'] && 'active-tab'}`}
        >
          Monthly
        </div>
        <div
          onClick={() => onChange(tabs['YEARLY'])}
          className={`${activeTab === tabs['YEARLY'] && 'active-tab'}`}
        >
          Premium
        </div>
      </div>

      {/* <div className='card_holder'>
        <div className='card-ctr'>
          <p className='plan-type'>Free</p>
          <p className='plan-price'>Free For All Users</p>

          <div className='plan_benefits'>
            <div className='benefit'>
              <CheckCircleFilled className='icon' />
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.,</p>
            </div>
            <div className='benefit'>
              <CheckCircleFilled className='icon' />
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.,</p>
            </div>
            <div className='benefit'>
              <CheckCircleFilled className='icon' />
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.,</p>
            </div>
            <div className='benefit'>
              <CheckCircleFilled className='icon' />
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.,</p>
            </div>
          </div>

          <GoPremiumBtn text='Get Started For Free' />
        </div>
      </div> */}

      <div className='card_holder'>
        <div
          className={`card-ctr ${
            activeTab === tabs['FREE'] ? 'mobile_card-ctr' : 'hidden'
          } `}
        >
          <p className='plan-type'>Free</p>
          <p className='plan-price'>Free For All Users</p>

          <div className='plan_benefits'>
            <div className='benefit'>
              <CheckCircleFilled className='icon' />
              <p>Supports two symbols.</p>
            </div>
            <div className='benefit'>
              <CheckCircleFilled className='icon' />
              <p>Includes one astrological indicator for a planet.</p>
            </div>
            <div className='benefit'>
              <CheckCircleFilled className='icon' />
              <p>Supports all technical indicators.</p>
            </div>
            <div className='benefit not-allowed'>
              <CheckCircleFilled className='icon' />
              <p>Requests for adding new symbols are included.</p>
            </div>
          </div>

          <GoPremiumBtn text='Get Started For Free' />
        </div>

        <div
          className={`card-ctr ${
            activeTab === tabs['MONTHLY'] ? 'mobile_card-ctr' : 'hidden'
          } `}
        >
          <p className='plan-type'>Monthly</p>
          <p className='plan-price'>₹ 800 / m</p>

          <div className='plan_benefits'>
            <div className='benefit'>
              <CheckCircleFilled className='icon' />
              <p>Unlimited symbol support.</p>
            </div>
            <div className='benefit'>
              <CheckCircleFilled className='icon' />
              <p>Includes all planet astrological indicators.</p>
            </div>
            <div className='benefit'>
              <CheckCircleFilled className='icon' />
              <p>Supports all technical indicators.</p>
            </div>
            <div className='benefit not-allowed'>
              <CheckCircleFilled className='icon' />
              <p>Requests for adding new symbols are included.</p>
            </div>
          </div>

          <GoPremiumBtn />
          {/* <p className='go-premium'>
            or skip trial and <span>pay now</span>{' '}
          </p> */}
        </div>

        <div
          className={`card-ctr ${
            activeTab === tabs['YEARLY'] ? 'mobile_card-ctr' : 'hidden'
          } `}
        >
          <p className='plan-type'>Yearly</p>
          <p className='plan-price'>₹ 8000 / yr</p>

          <div className='plan_benefits'>
            <div className='benefit'>
              <CheckCircleFilled className='icon' />
              <p>Unlimited symbol support.</p>
            </div>
            <div className='benefit'>
              <CheckCircleFilled className='icon' />
              <p>Includes all planet astrological indicators.</p>
            </div>
            <div className='benefit'>
              <CheckCircleFilled className='icon' />
              <p>Supports all technical indicators.</p>
            </div>
            <div className='benefit'>
              <CheckCircleFilled className='icon' />
              <p>Requests for adding new symbols are included.</p>
            </div>
          </div>

          <GoPremiumBtn />
        </div>
      </div>
    </div>
  );
};

export default Pricing;
