import { createSlice, current } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import moment from 'moment';
import {
  combinationModeOptions,
  neighboursOptions,
} from '../../Utils/settings';

const initialState = {
  eventScrollPosition: 0,
  eventTurningPoints: [],
  eventData: [],
  calendarData: {},
  initialPayload: {
    month: dayjs().month(),
    year: dayjs().year(),
    city: 'Mumbai',
    // exchange_name: searchParams.exchange_name,
    // symbol: searchParams.symbol,
    combination_count: 1,
    success_rate: 50,
    total_count: 5,
    mode: combinationModeOptions[0].value,
    neighbour: neighboursOptions[0].value,
  },
  selectedEventList: [],
};

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setselectedEventList: (state, { payload }) => {
      const { type, data } = payload;
      // state = current(state)
      if (type === 'ADD') {
        state.selectedEventList.push(data);
      } else if (type === 'REMOVE') {
        state.selectedEventList = current(state.selectedEventList).filter(
          (item) => item.key !== data.key
        );
      } else {
        state.selectedEventList = []
      }
    },
    setinitialPayload: (state, { payload }) => {
      state.initialPayload = {
        ...state.initialPayload,
        ...payload,
      };
    },
    setEventTurningPoint: (state, action) => {
      const newData = action.payload;
      state.eventTurningPoints = [
        {
          time: newData.date,
          position: 'aboveBar',
          color: '#0F6CBD',
          shape: 'arrowDown',
          text: moment(newData.date).format('DD MMM YYYY'),
        },
      ];
    },
    setEventScrollPosition: (state, { payload }) => {
      state.scrollPosition = payload;
    },
    setEventData: (state, { payload }) => {
      state.eventData = payload;
    },
    setCalendarData: (state, { payload }) => {
      state.calendarData = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setEventScrollPosition,
  setEventTurningPoint,
  setEventData,
  setCalendarData,
  setinitialPayload,
  setselectedEventList,
} = eventSlice.actions;

export default eventSlice.reducer;
