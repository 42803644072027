import { Button, Card, Form, Modal, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import {
  getAngleColumnAction,
  getAngleColumnValuesAction,
} from '../../../Screens/Action';
import { combinationModeOptions } from '../../../Utils/settings';

const PlanetAnglesFilter = ({
  openModal = false,
  onFinish = () => {},
  modalClosed = () => {},
  initialVlaues,
}) => {
  const [form] = Form.useForm();
  const planetSelectRef = useRef();
  const planetAngleSelectRef = useRef();

  const [angleColumnNames, setAngleColumnNames] = useState([]);
  const [angleColumnValues, setAngleColumnValues] = useState([]);

  const [planetValues, setPlanetValues] = useState([]);

  const getAngleColumnValues = (values) => {
    getAngleColumnValuesAction({ payload: values }).then(({ data, error }) => {
      if (!error) {
        setAngleColumnValues(data);
      }
    });
  };

  const getAngleColumn = () => {
    getAngleColumnAction().then(({ data, error, message }) => {
      if (!error) {
        setAngleColumnNames(data);

        // getAngleColumnValues([data[0]]);
      }
    });
  };

  useEffect(() => {
    getAngleColumn();
    form.resetFields();
  }, []);

  return (
    <Modal centered open={openModal} closable={false} footer={null}>
      <Card title='Filter Angles'>
        {/* <h4>Select Angles:</h4> */}
        <Form
          form={form}
          onFinish={onFinish}
          labelCol={{ span: 7 }}
          labelWrap
          initialValues={initialVlaues}
        >
          <Form.Item
            label='Planets'
            name={'planets'}
            rules={[{ required: true }]}
          >
            <Select
              ref={planetSelectRef}
              placeholder='Select Angles'
              style={{
                width: '100%',
              }}
              mode='multiple'
              onChange={(e) => {
                planetSelectRef.current.blur();
                setPlanetValues(e);
                getAngleColumnValues(e)
              }}
              // onBlur={() => getAngleColumnValues(planetValues)}
            >
              {angleColumnNames.map((name) => (
                <Select.Option value={name}>{name}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label='Mode' name={'mode'} rules={[{ required: true }]}>
            <Select
              placeholder='Select Mode'
              style={{
                width: '100%',
              }}
              options={combinationModeOptions}
            />
          </Form.Item>

          <Form.Item label='Planet Angles' name={'planet_angles'}>
            <Select
              ref={planetAngleSelectRef}
              placeholder='Select Planet Angles'
              style={{
                width: '100%',
              }}
              onChange={() => planetAngleSelectRef.current.blur()}
              mode='multiple'
            >
              {angleColumnValues.map((name) => (
                <Select.Option value={name}>{name}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label='Planet Angle Values'
            name={'planet_angle_values'}
            rules={[
              {
                validator: (_, value) => {
                  if (!value || value.every((val) => !isNaN(parseFloat(val)))) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('Please enter numerical values only')
                  );
                },
              },
            ]}
          >
            <Select
              placeholder='Enter Planet Angle Values'
              style={{
                width: '100%',
              }}
              mode='tags'
            />
          </Form.Item>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              onClick={modalClosed}
              style={{
                marginRight: 20,
              }}
              danger
            >
              Cancel
            </Button>
            <Form.Item
              style={{
                marginBottom: 0,
              }}
            >
              <Button type='primary' htmlType='submit'>
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Card>
    </Modal>
  );
};

export default PlanetAnglesFilter;
