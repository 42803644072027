import React from 'react';
import logo from './no-data.png';
import { Typography } from 'antd';

export const NoData = () => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 20,
      marginBottom: 20
    }}>
      <img src={logo} style={{
        height: 70,
      }} />
      <Typography.Text type='secondary'>No Data</Typography.Text>
      {/* <h3>No Data</h3> */}
    </div>
  );
};
