import React, { useEffect } from 'react';
import Login from './Screens/Auth/Login';
import { RouterProvider, createHashRouter } from 'react-router-dom';
import ErrorPage from './Utils/ErrorPage';
import Register from './Screens/Auth/Register';
import Layout from './Layouts/Layout';
import Dashboards from './Screens/Dashboard/Dashboards';
import PlanetAngles from './Screens/PlanetAngles/PlanetAngles';
import Events from './Screens/Events/Events';
import ResetPassword from './Screens/Auth/ResetPassword';
import AllPlans from './Screens/Subscription/AllPlans';
import VerificationComplete from './Screens/Auth/VerificationComplete';
import AuthLayout from './Layouts/AuthLyout';
import SetPassword from './Screens/Auth/SetPassword';
import Privacy from './Screens/LandingPage/Components/PrivacyPolicy';
import TermsServices from './Screens/LandingPage/Components/TermsServices';
import HomeScreen from './Screens/LandingPage/LandingScreen';
import zipy from 'zipyai';
import { zipyCode } from './Utils/settings';

export default function App() {
  // useEffect(() => {
  //   zipy.init(zipyCode);
  // }, []);
  
  const appRouter = createHashRouter([
    {
      path: '/',
      element: <HomeScreen />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/privacy_policy',
      element: <Privacy />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/terms_and_services',
      element: <TermsServices />,
      errorElement: <ErrorPage />,
    },
    {
      path: 'auth',
      element: <AuthLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: 'user_verified',
          element: <VerificationComplete />,
          errorElement: <ErrorPage />,
        },
        {
          path: 'login',
          element: <Login />,
          errorElement: <ErrorPage />,
        },
        {
          path: 'register',
          element: <Register />,
          errorElement: <ErrorPage />,
        },
        {
          path: 'reset_password',
          element: <ResetPassword />,
          errorElement: <ErrorPage />,
        },
        {
          path: 'set_password',
          element: <SetPassword />,
          errorElement: <ErrorPage />,
        },
      ],
    },
    {
      path: 'trade',
      element: <Layout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: 'planet_angles',
          element: <PlanetAngles />,
        },
        {
          path: 'combinations',
          element: <Dashboards />,
        },
        {
          path: 'event_details',
          element: <Dashboards />,
        },
        {
          path: 'moon_phases',
          element: <Dashboards />,
        },
        {
          path: 'events',
          element: <Events />,
        },
        {
          path: 'all-plans',
          element: <AllPlans />,
        },
      ],
    },
  ]);

  return <RouterProvider router={appRouter} />;
}
