import {
  Button,
  Card,
  Checkbox,
  Collapse,
  Divider,
  Spin,
  Tag,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetAngleCombination,
  setAngleCombination,
  setScrollPosition,
  setSelectedMoonPhaseCombinations,
  setUniqueDates,
} from '../../../redux/dashboardSlice/dashSlice';
import { colIncluded, goToPositionOnChart } from '../../../Utils/settings';
import {
  CloseCircleOutlined,
  FilterOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import FilterCombinationModal from './FilterCombinationModal';
import getCombinationsCall from '../Functions/getCombinationsCall';
import DownloadCombination from './DownloadCombination';
import { moonPhase } from '../../../Utils/DummyData';
import { useLocation } from 'react-router-dom';
import { NoData } from '../../NoData';

export default function AngleCombinationPanel({ angleData = {} }) {
  const dispatch = useDispatch();
  const [controller, setcontroller] = useState();
  const { pathname } = useLocation();

  const isEventPage = pathname === '/event_details';

  let {
    chartData,
    moonPhaseCombinations,
    selectedMoonPhaseCombinations,
    uniqueDates,
    combinationLoading,
    searchParams,
  } = useSelector((state) => state.dashboard);

  const { eventData, initialPayload: reduxInitialPayload } = useSelector(
    (state) => state.event
  );

  const { getCombinationController } = useSelector(
    (state) => state.apiControllers
  );

  // const [checked, setChecked] = useState(false);
  const [openFilterModal, setopenFilterModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [initialFilterObj, setinitialFilterObj] = useState({
    exchange_name: searchParams.exchange_name,
    symbol: searchParams.symbol,
    years: 15,
    selected_config: isEventPage ? reduxInitialPayload.selected_config : 5,
    combination_count: isEventPage ? reduxInitialPayload.combination_count : 3,
    neighbour: isEventPage ? reduxInitialPayload.neighbour : 20,
    mode: isEventPage ? reduxInitialPayload.mode : 0,
    success_rate: isEventPage ? angleData.success_rate : 60,
    total_count: isEventPage ? angleData.total_event : 5,
    includes_columns: isEventPage
      ? [angleData.pattern_name]
      : [colIncluded[colIncluded.length - 1]],
  });

  useEffect(() => {
    if (isEventPage) {
      dispatch(
        setSelectedMoonPhaseCombinations({
          type: 'RESET',
        })
      );
      dispatch(
        setUniqueDates({
          type: 'RESET',
        })
      );
      dispatch(resetAngleCombination());
    }
  }, [isEventPage]);

  function dateClicked({ date }) {
    const position = goToPositionOnChart({ date: date, data: chartData });
    if (position) dispatch(setScrollPosition(position));
  }

  function createTurningPoint({ data }) {
    const flattenedDates = data.total_dates_dict
      .map((item) => ({
        time: new Date(item.date).toISOString().split('T')[0],
        ...item,
        // text: data.combinations
      }))
      .sort((a, b) => a.time.localeCompare(b.time));

    return flattenedDates;
  }

  function onSelected({ checked, data, dataIndex }) {
    // setChecked(checked);
    dispatch(
      setUniqueDates({
        type: 'ADD',
        newData: data.total_dates_dict,
      })
    );

    // return
    const finalArr = createTurningPoint({ data: data });
    
    dispatch(setAngleCombination({ data: finalArr, checked: checked }));

    dispatch(
      setSelectedMoonPhaseCombinations({
        type: 'ADD',
        data: data,
        dataIndex: dataIndex,
      })
    );
  }

  function onRemoved({ checked, data, dataIndex }) {
    // setChecked(checked);
    dispatch(
      setUniqueDates({
        type: 'REMOVE',
        newData: data.total_dates_dict,
      })
    );

    const finalArr = createTurningPoint({ data: data });
    dispatch(setAngleCombination({ data: finalArr, checked: checked }));

    dispatch(
      setSelectedMoonPhaseCombinations({
        type: 'REMOVE',
        data: data,
        dataIndex: dataIndex,
      })
    );
  }

  useEffect(() => {
    // return;
    getCombinationsCall({
      dispatch,
      getCombinationController,
      initialFilterObj: {
        ...initialFilterObj,
        exchange_name: searchParams.exchange_name,
        symbol: searchParams.symbol,
      },
      page_number: pageNumber,
    });
  }, [initialFilterObj, searchParams, pageNumber]);

  function PanelHeader({ data }) {
    return (
      <div className='collapseHeaderCtr'>
        <Typography.Text>{data.combinations}</Typography.Text>
        <div className='collapseHeaderContent'>
          <Typography.Text type='secondary'>
            <span>Value : </span>
            {data.values}
          </Typography.Text>
          <Typography.Text type='secondary'>
            <span>S/R : </span>
            {data.success_rate} %
          </Typography.Text>
          <Typography.Text type='secondary'>
            <span>Event Count : </span>
            {data.sum_of_all_count}
          </Typography.Text>
          <Typography.Text type='secondary'>
            <span>Swing Count : </span>
            {data.total_count}
          </Typography.Text>
        </div>
      </div>
    );
  }

  function DateTag({ item }) {
    return (
      <Tag
        className='tag'
        style={{
          backgroundColor: item?.is_event_date ? '#b2f9ae80' : '#ff925c80',
          borderColor: item?.is_event_date ? '#62E075' : '#FF935C',
        }}
        onClick={() => dateClicked(item)}
      >
        {moment(item?.date).format('DD MMM YYYY')}
      </Tag>
    );
  }

  function MoonCombinationData({ data }) {
    return (
      <Card className='moon_phase-card'>
        <h3 className='moon_phase-dates'>Dates</h3>
        <div className='moon_phase-dates-ctr'>
          {data.total_dates_dict.map((item) => (
            <DateTag item={item} />
          ))}
        </div>

        <Divider />

        {/* <h3 className='moon_phase-dates'>Stats</h3> */}
        <div className='moon_phase_ctr'>
          <p className='text'>
            Values: <span>{data.values}</span>
          </p>

          <p className='text'>
            Day -3 Count: <span>{data['count_day_-3']}</span>
          </p>

          <p className='text'>
            Day -2 Count: <span>{data['count_day_-2']}</span>
          </p>

          <p className='text'>
            Day -1 Count: <span>{data['count_day_-1']}</span>
          </p>

          <p className='text'>
            Day 0 Count: <span>{data.count_day_0}</span>
          </p>

          <p className='text'>
            Day 1 Count: <span>{data.count_day_1}</span>
          </p>

          <p className='text'>
            Day 2 Count: <span>{data.count_day_2}</span>
          </p>

          <p className='text'>
            Day 3 Count: <span>{data.count_day_3}</span>
          </p>

          <p className='text'>
            Total Event Count: <span>{data.sum_of_all_count}</span>
          </p>

          <p className='text'>
            Swing Count: <span>{data.total_count}</span>
          </p>

          <p className='text'>
            Success Rate: <span>{data.success_rate} %</span>
          </p>
        </div>
      </Card>
    );
  }

  return (
    <>
      <FilterCombinationModal
        open={openFilterModal}
        setModal={() => setopenFilterModal(false)}
        initialFilterObj={initialFilterObj}
        setinitialFilterObj={(values) => {
          dispatch(
            setSelectedMoonPhaseCombinations({
              type: 'RESET',
            })
          );
          dispatch(
            setUniqueDates({
              type: 'RESET',
            })
          );
          dispatch(resetAngleCombination());
          setPageNumber(1);
          setinitialFilterObj((vv) => ({
            ...vv,
            combination_count: Number(values.combination_count),
            success_rate: Number(values.success_rate),
            total_count: Number(values.total_count),
            includes_columns: values.includes_columns,
            neighbour: values.neighbour,
            mode: values.mode,
          }));
        }}
      />

      <div className='filter_ctr'>
        <DownloadCombination initialFilterObj={initialFilterObj} />

        {!isEventPage && (
          <Tooltip placement='left' title='Filter Combinations'>
            <Button
              className='filter_icon'
              onClick={() => setopenFilterModal(true)}
              icon={<FilterOutlined />}
            />
          </Tooltip>
        )}
      </div>

      {/* spinning={combinationLoading} */}
      <Spin tip='Loading...' spinning={false}>
        {/* Modal */}

        <Collapse className='moon_phase-collapse' bordered={false}>
          {uniqueDates.length > 0 && (
            <>
              <h3 className='moon_phase-dates'>
                Unique Dates ({`${uniqueDates.length}`})
              </h3>
              <div className='color_ctr'>
                <div className='color_sub-ctr'>
                  <div
                    className='circle'
                    style={{
                      backgroundColor: '#FF935C',
                    }}
                  />
                  <Typography.Text>
                    {uniqueDates.filter((vv) => !vv.is_event_date).length}
                  </Typography.Text>
                </div>

                <div className='color_sub-ctr'>
                  <div
                    className='circle'
                    style={{
                      backgroundColor: '#62E075',
                    }}
                  />
                  <Typography.Text>
                    {uniqueDates.filter((vv) => vv.is_event_date).length}
                  </Typography.Text>
                </div>
              </div>
              <div
                className='moon_phase-dates-ctr'
                style={{
                  marginBottom: 20,
                }}
              >
                {/* <p>Unique Dates</p> */}
                {uniqueDates.map((item) => (
                  <DateTag item={item} />
                ))}
              </div>
            </>
          )}

          <>
            {selectedMoonPhaseCombinations.map((data, index) => {
              return (
                <Collapse.Panel
                  header={<PanelHeader data={data} />}
                  key={`selected_${index}`}
                  extra={
                    <Tooltip title={'Remove from Chart'} placement='left'>
                      <CloseCircleOutlined
                        className='collapse_icon collapse_icon-close'
                        onClick={(event) => {
                          event.stopPropagation();

                          onRemoved({
                            data: data,
                            checked: false,
                            dataIndex: index,
                          });
                        }}
                      />
                    </Tooltip>
                  }
                >
                  <MoonCombinationData data={data} />
                </Collapse.Panel>
              );
            })}

            {/* {selectedMoonPhaseCombinations.length > 0 && <Divider />} */}

            {/* moonPhaseCombinations */}
            {moonPhaseCombinations.map((data, index) => {
              return (
                <>
                  <Collapse.Panel
                    header={<PanelHeader data={data} />}
                    key={index}
                    extra={
                      <Tooltip title={'Show On Chart'} placement='left'>
                        <PlusCircleOutlined
                          className='collapse_icon'
                          onClick={(event) => {
                            event.stopPropagation();

                            onSelected({
                              data: data,
                              checked: true,
                              dataIndex: index,
                            });
                          }}
                        />
                      </Tooltip>
                    }
                  >
                    <MoonCombinationData data={data} />
                  </Collapse.Panel>
                </>
              );
            })}
          </>
        </Collapse>

        {moonPhaseCombinations.length === 0 && !combinationLoading && (
          <NoData />
        )}

        <div className='pagination_ctr'>
          <Button
            onClick={() => {
              setPageNumber((prev) => {
                if (prev === 1) return;
                else {
                  // dispatch(
                  //   setSelectedMoonPhaseCombinations({
                  //     type: 'RESET',
                  //   })
                  // );
                  // dispatch(
                  //   setUniqueDates({
                  //     type: 'RESET',
                  //   })
                  // );
                  return prev - 1;
                }
              });
            }}
            disabled={pageNumber === 1}
            type='primary'
          >
            Prev
          </Button>
          <Typography.Text className='pagination_text'>
            {pageNumber}
          </Typography.Text>
          <Button
            disabled={moonPhaseCombinations.length < 10}
            onClick={() => {
              // dispatch(
              //   setSelectedMoonPhaseCombinations({
              //     type: 'RESET',
              //   })
              // );
              // dispatch(
              //   setUniqueDates({
              //     type: 'RESET',
              //   })
              // );
              setPageNumber((prev) => prev + 1);
            }}
            type='primary'
          >
            Next
          </Button>
        </div>
      </Spin>
    </>
  );
}
