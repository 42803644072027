// import { combineReducers, configureStore } from '@reduxjs/toolkit';
// // import { dashboardSlice } from './dashboardSlice/dashSlice';
// import dashboardReducer from './dashboardSlice/dashSlice';
// import eventReducer from './eventSlice/eventSlice';
// import planetReducer from './planetSlice/planetSlice';
// import controllerSlice from './apiControllerSlice/apiControllerSlice';
// import storage from 'redux-persist/lib/storage';
// import {
//   FLUSH,
//   PAUSE,
//   PERSIST,
//   PURGE,
//   REGISTER,
//   REHYDRATE,
//   persistReducer,
// } from 'redux-persist';

// const persistConfig = {
//   key: 'root',
//   storage,
// };

// const appReducer = combineReducers({
//   dashboard: dashboardReducer,
//   event: eventReducer,
//   planet: planetReducer,
//   apiControllers: controllerSlice,
// });

// const rootReducer = (state, action) => {
//   if (action.type === 'USER_LOGOUT') {
//     storage.removeItem('persist:root');
//     return appReducer(undefined, action);
//   }

//   return appReducer(state, action);
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const store = configureStore({
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: {
//         ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//       },
//     }),
// });

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import dashboardReducer from './dashboardSlice/dashSlice';
import eventReducer from './eventSlice/eventSlice';
import planetReducer from './planetSlice/planetSlice';
import controllerSlice from './apiControllerSlice/apiControllerSlice';

// export const store = configureStore({
//   reducer: {
//     dashboard: dashboardReducer,
//     event: eventReducer,
//     planet: planetReducer,
//     apiControllers: controllerSlice,
//   },
// });

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  event: eventReducer,
  planet: planetReducer,
  apiControllers: controllerSlice,
});

const rootReducerWithLogout = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    // Handle logout if needed
    // You might want to reset the state to initial state for specific reducers
    return rootReducer(undefined, action);
  }

  return rootReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducerWithLogout,
});

// const rootReducer = (state, action) => {
//   if (action.type === 'USER_LOGOUT') {
//     // Handle logout if needed
//     // You might want to reset the state to initial state for specific reducers
//     return appReducer(undefined, action);
//   }

//   return appReducer(state, action);
// };

// export const store = configureStore({
//   reducer: rootReducer,
//   // middleware: (getDefaultMiddleware) =>
//   //   getDefaultMiddleware({
//   //     serializableCheck: false, // Disable serializable check
//   //   }),
// });
