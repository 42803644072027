import React, { useEffect, useRef, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isLoggedIn } from '../../Utils/settings';

import LOGO from './Assets/Logo.png';
import './Components/navBar.css';
import './landing.css';
import Benefits from './Components/Benefits';
import Pricing from './Components/Pricing';
import Videos from './Components/Videos';
import Footer from './Components/Footer';
import dayjs from 'dayjs';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';

const HomeScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const homeRef = useRef();
  const benefitsRef = useRef();
  const pricingRef = useRef();
  const videoRef = useRef();

  const [pageScrolled, setPageScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState('HOME');
  const [navOpen, setNavOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleOk = () => {
    setIsModalOpen(false);
    window.open('https://pickmytrade.trade/', '_blank');
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const toggleNav = () => {
    setNavOpen((e) => !e);
  };

  const handleScroll = () => {
    if (window.scrollY >= (8 * window.innerHeight) / 100) {
      setPageScrolled(true);
    } else {
      setPageScrolled(false);
    }
  };

  window.addEventListener('scroll', handleScroll);

  useEffect(() => {
    if (isLoggedIn()) {
      localStorage.clear();
      dispatch({ type: 'USER_LOGOUT' });
      navigate('/');
    }

    const interval = setInterval(() => {
      setIsModalOpen(false);
    }, 15000);

    return () => clearInterval(interval);
  }, []);

  const scrollToSection = (elmRef) => {
    window.scrollTo({ top: elmRef.current.offsetTop, behavior: 'smooth' });
  };

  return (
    <div className='container'>
      {/* <PrivacyPolicy
        open={openPrivacyPolicy}
        closeModal={() => setopenPrivacyPolicy(false)}
      />

      <TermsAndServices
        open={openTerms}
        closeModal={() => setOpenTerms(false)}
      /> */}
      <Modal
        title={null}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={'Sign Up'}
      >
        <div className='flex flex-col items-center justify-center gap-4 py-8'>
          <BoltIcon className='size-12 text-primary' />
          <div className='space-y-2 text-center'>
            <h2>Automate Your Trades</h2>
            <p>
              If you are looking to automate your trade with Tradovate's
              advanced automation tools allow you to effortlessly execute your
              trading strategies. Sign up now to unlock the power of automated
              trading. <br /> Link: https://pickmytrade.trade/
            </p>
            <br />
          </div>
        </div>
      </Modal>

      <div ref={homeRef} className='homescreen'>
        <div className={`nav_bar-ctr ${pageScrolled && 'fixed'}`}>
          <img
            className='logo'
            src={LOGO}
            alt='LOGO'
            onClick={() => {
              scrollToSection(homeRef);
              setActiveSection('HOME');
            }}
          />

          <div className='link-ctr'>
            <div
              className={`link ${activeSection === 'HOME' && 'active'}`}
              onClick={() => {
                scrollToSection(homeRef);
                setActiveSection('HOME');
              }}
            >
              HOME
            </div>
            <div
              className={`link ${activeSection === 'BENEFITS' && 'active'}`}
              onClick={() => {
                scrollToSection(benefitsRef);
                setActiveSection('BENEFITS');
              }}
            >
              BENEFITS
            </div>
            <div
              className={`link ${activeSection === 'PRICING' && 'active'}`}
              onClick={() => {
                scrollToSection(pricingRef);
                setActiveSection('PRICING');
              }}
            >
              PRICING
            </div>
            {/* <div
              className={`link ${activeSection === 'VIDEOS' && 'active'}`}
              onClick={() => {
                scrollToSection(videoRef);
                setActiveSection('VIDEOS');
              }}
            >
              VIDEOS
            </div> */}
          </div>

          <div className='auth-ctr'>
            <Link to={'/auth/register'} target='_blank' className='auth-btn'>
              Sign-Up
            </Link>
            <Link to={'/auth/login'} target='_blank' className='auth-btn'>
              Login
            </Link>
          </div>

          {navOpen ? (
            <CloseOutlined className='nav_menu' onClick={toggleNav} />
          ) : (
            <MenuOutlined className='nav_menu' onClick={toggleNav} />
          )}

          <div
            className={`mobile_nav-ctr ${pageScrolled && 'scrolled'} ${
              navOpen && 'navopen'
            }`}
          >
            <div
              className='mobile-nav-links'
              onClick={() => {
                scrollToSection(homeRef);
                setActiveSection('HOME');
                toggleNav();
              }}
            >
              Home
            </div>
            <div
              className='mobile-nav-links'
              onClick={() => {
                scrollToSection(benefitsRef);
                setActiveSection('BENEFITS');
                toggleNav();
              }}
            >
              Benefits
            </div>
            <div
              className='mobile-nav-links'
              onClick={() => {
                scrollToSection(pricingRef);
                setActiveSection('PRICING');
                toggleNav();
              }}
            >
              Pricing
            </div>
            {/* <div
              className='mobile-nav-links'
              onClick={() => {
                scrollToSection(videoRef);
                setActiveSection('VIDEOS');
                toggleNav();
              }}
            >
              Videos
            </div> */}

            {/* Auth Buttons */}
            <div
              className='mobile-nav-links mobile-login'
              onClick={() => {
                navigate('/auth/login');
              }}
            >
              Login
            </div>
            <div
              className='mobile-nav-links'
              onClick={() => {
                navigate('/auth/register');
              }}
            >
              Register
            </div>
          </div>
        </div>
      </div>

      <div className='home_content-ctr'>
        <h1 className='header'>Pick My Trade</h1>
        <p className='header-sub'>
          Navigating the Financial Universe Through Astrology
        </p>

        <Link to={'/auth/login'} className='header-btn' target='_blank'>
          Get Started
        </Link>
      </div>

      <div className='section-ctr' ref={benefitsRef}>
        <Benefits />
      </div>

      <div ref={pricingRef} className='section-ctr'>
        <Pricing />
      </div>

      {/* <div ref={videoRef} className='section-ctr'>
        <Videos />
      </div> */}

      <div className='section-ctr'>
        <div className='footer-ctr'>
          <div className='section-ctr'>
            {/* Left Section */}
            <div className='left'>
              <div className='img-logo'>
                <img
                  className='logo'
                  src={LOGO}
                  alt='LOGO'
                  onClick={() => {
                    scrollToSection(homeRef);
                    setActiveSection('HOME');
                  }}
                />
                <div className='logo-text'>
                  <h2>Pick My Trade</h2>
                  <p> Navigating the Financial Universe Through Astrology.</p>
                </div>
              </div>
            </div>

            {/* Right Section */}
            <div className='right'>
              <div className='url'>
                <p
                  className='footerlinks'
                  onClick={() => {
                    scrollToSection(homeRef);
                    setActiveSection('HOME');
                  }}
                >
                  Home
                </p>
                <p
                  className='footerlinks'
                  onClick={() => {
                    scrollToSection(benefitsRef);
                    setActiveSection('BENEFITS');
                  }}
                >
                  Benefits
                </p>
                <p
                  className='footerlinks'
                  onClick={() => {
                    scrollToSection(pricingRef);
                    setActiveSection('PRICING');
                  }}
                >
                  Pricing
                </p>
                {/* <p
                  className='footerlinks'
                  onClick={() => {
                    scrollToSection(videoRef);
                    setActiveSection('VIDEOS');
                  }}
                >
                  Videos
                </p> */}
              </div>
              <div className='url_2'>
                <Link
                  to='/terms_and_services'
                  className='footerlinks'
                  target='_blank'
                >
                  Terms of Services
                </Link>

                <Link
                  to='/privacy_policy'
                  className='footerlinks'
                  target='_blank'
                >
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>

          <div className='divider' />
          <p className='copyright'>{`Copyright ${String.fromCharCode(
            169,
          )} ${dayjs().year()} PickMyTrade. All Rights Reserved.`}</p>
        </div>
      </div>
    </div>
  );
};

export default HomeScreen;

function BoltIcon(props) {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z' />
      <circle cx='12' cy='12' r='4' />
    </svg>
  );
}
