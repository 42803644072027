import { Button, Card, Form, Input, Modal, Select } from 'antd';
import React, { useEffect } from 'react';
import {
  colIncluded,
  combinationModeOptions,
  validateNumber,
} from '../../../Utils/settings';

export default function FilterCombinationModal({
  open = false,
  setModal = () => {},
  initialFilterObj = {},
  setinitialFilterObj = {},
}) {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      combination_count: initialFilterObj.combination_count,
      success_rate: initialFilterObj.success_rate,
      total_count: initialFilterObj.total_count,
      includes_columns: initialFilterObj.includes_columns,
      neighbour: initialFilterObj.neighbour,
      mode: initialFilterObj.mode
    });
  }, [open]);

  return (
    <Modal closable={false} open={open} width={800} footer={null}>
      <Card
        title='Filter Combinations'
        style={{
          marginTop: 20,
        }}
      >
        <Form
          form={form}
          labelCol={{ span: 7 }}
          labelWrap
          
          onFinish={(e) => {
            setinitialFilterObj(e);
            setModal();
          }}
        >
          <Form.Item name={'combination_count'} label='Combination Count'>
            <Select>
              <Select.Option value={1}>1</Select.Option>
              <Select.Option value={2}>2</Select.Option>
              <Select.Option value={3}>3</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name={'success_rate'}
            label='Success Rate'
            rules={[
              {
                required: true,
                message: 'Please enter a number.',
              },
              { validator: validateNumber },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name={'total_count'}
            label='Total Count'
            rules={[
              {
                required: true,
                message: 'Please enter a number.',
              },
              { validator: validateNumber },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name={'neighbour'}
            label='Neighbour'
            rules={[
              {
                required: true,
                message: 'Please Select Neighbour.',
              },
            ]}
          >
            <Select>
              <Select.Option value={5}>5</Select.Option>
              <Select.Option value={10}>10</Select.Option>
              <Select.Option value={15}>15</Select.Option>
              <Select.Option value={20}>20</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name={'mode'}
            label='Mode'
            rules={[
              {
                required: true,
                message: 'Please Select Mode.',
              },
            ]}
          >
            <Select options={combinationModeOptions} />
          </Form.Item>

          <Form.Item name={'includes_columns'} label='Include Combinations'>
            <Select mode='tags'>
              {colIncluded.map((vv) => (
                <Select.Option value={vv}>{vv}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <div className='filter_form_buttons-ctr'>
            <Button danger className='form-buttons' onClick={setModal}>
              Cancel
            </Button>
            <Form.Item className='form-item'>
              <Button htmlType='submit' type='primary' className='form-buttons'>
                Apply
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Card>
    </Modal>
  );
}
