import { Button, Form, Input, Layout, notification } from 'antd';
import React, { useState } from 'react';
import './auth.css';
import { useNavigate } from 'react-router-dom';
import { FrownFilled, LoginOutlined, SmileFilled } from '@ant-design/icons';
import { getMeAction, loginAction } from './Action';
import TradingView from '../../Components/TradingViewDownload';
import zipy from 'zipyai';
import { isSupportedEmail, zipyCode } from '../../Utils/settings';

export default function Login() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const onFinish = async (values) => {
    if (isSupportedEmail(values.username)) {
      console.log('initializing zipy');
      zipy.init(zipyCode);
    }

    await loginAction({ payload: { ...values, user_otp: 1234 } }).then(
      ({ message, error, data }) => {
        if (!error) {
          localStorage.setItem('token', JSON.stringify(data.token));

          getMeAction().then(
            ({ message: message2, error: error2, data: data2 }) => {
              if (!error2) {
                localStorage.setItem('logInUser', JSON.stringify(data2));
                localStorage.setItem('new_user', JSON.stringify(data.new_user));

                zipy.identify(data2?.email, {
                  user: data2?.email,
                  firstName: data2?.name,
                  email: data2?.email,
                  customerName: data2?.name,
                });

                api.open({
                  message: (
                    <p className='notification-title'>Successfully logged in</p>
                  ),
                  description: data.new_user
                    ? 'Your 15-day trial is now live! Enjoy exploring our features. Need help? Just ask!'
                    : 'Welcome back to your account. Redirecting To Dashboard...',
                  icon: <SmileFilled className='success' />,
                });

                api.destroy();
                navigate('/trade/planet_angles', { replace: true });
                // setTimeout(() => {
                // }, 1000);
              } else {
                notification.error({ message: message2 });
              }
            },
          );
        } else {
          notification.error({ message, duration: 2 });
        }
      },
    );

    setLoading(false);
  };

  const onSignup = () => {
    navigate('/auth/register', { replace: true });
  };

  const onResetPassword = () => {
    navigate('/auth/reset_password');
  };

  return (
    <Layout className='login-ctr'>
      {contextHolder}
      <div className='login-box'>
        <h2 className='login_title'>Welcome back</h2>
        <p className='login_subtitle'>
          Glad to see you again 👋 <br /> Login to your account below.
        </p>

        <Form
          form={form}
          className='login-form_ctr'
          onFinish={onFinish}
          autoComplete={false}
          autocomplete='none'
          // initialValues={{ username: 'silewaj286@otemdi.com', password: 'asdf' }}
        >
          <p className='item_title'>User Email</p>
          <Form.Item
            name={'username'}
            rules={[
              {
                type: 'email',
                message: 'Please enter a valid email address!',
              },
              {
                required: true,
                message: 'Email is required!',
              },
            ]}
          >
            <Input
              autocomplete='none'
              placeholder='Your Email...'
              className='form-item'
            />
          </Form.Item>

          <p className='item_title'>Password</p>
          <Form.Item
            name={'password'}
            rules={[
              {
                required: true,
                message: 'Password is required!',
              },
            ]}
          >
            <Input.Password
              autocomplete='none'
              placeholder='Your Password...'
              className='form-item'
            />
          </Form.Item>

          <p onClick={onResetPassword} className='forgot_password'>
            Forgot Password
          </p>

          <Form.Item>
            <Button
              loading={loading}
              icon={<LoginOutlined />}
              htmlType='submit'
              className='form-item form-item-btn '
            >
              Login
            </Button>
          </Form.Item>

          <p onClick={onSignup} className='signup-text'>
            Don't have an Account?
            <span>Sign up for free</span>
          </p>
        </Form>

        <TradingView />
      </div>
    </Layout>
  );
}
