import { Modal } from 'antd';
import React from 'react';

import './terms.css';

const PrivacyPolicy = ({ open, closeModal = () => {} }) => {
  return (
    <div className='privacypolicy-ctr'>
      <h2 className='ctr-header policy-header'>Privacy Policy</h2>
      The privacy of your data — and it is your data, not ours! — is a big deal
      to us. In this policy, we lay out: what data we collect and why; how your
      data is handled; and your rights to your data. We promise we never sell
      your data: never have, never will. <br />
      This policy applies to all products built and maintained by pickmytrade.{' '}
      <br />
      Whenever you want, you can download, export or delete everything you have
      uploaded on pickmytrade. As we said, they are your data – not ours.
      <p className='sub-header-modal'>What we collect and why</p>
      Our guiding principle is to collect only what we need. Here’s what that
      means in practice:
      <p className='sub-header-modal'>Identity & access</p>
      When you sign up for a pickmytrade product, we typically ask for
      identifying information such as your name and email address. That’s just
      so you can personalize your new account, and we can send you updates, or
      other essential information. That does not mean you have to give us your
      real name, you can make one up if you prefer; but an email address you
      have access to is needed to activate your account.
      <br /> If you opt for a native login, we may give you the option in the
      future to add a profile picture (with social login we’ll use the picture
      you have chosen for that platform) that displays in our products, but we
      do not normally look at or access that picture. We’ll never sell your
      personal information to third parties, and we won’t use your name or
      company in marketing statements without your permission either.
      <br />
      <p className='sub-header-modal'>Geolocation data</p>
      We log all access to all accounts by full IP address so that we can always
      verify no unauthorized access has happened. We may keep this login data
      for as long as your product account is active.
      <br /> We also log full IP addresses used to sign up a product account. We
      may keep this record forever because they are used to mitigate spammy
      signups.
      <br /> Web analytics data may also be tied temporarily to IP addresses to
      assist with troubleshooting cases.
      <br />
      <p className='sub-header-modal'>Website interactions</p>
      When you browse our pages or applications, your browser automatically
      shares certain information such as which operating system and browser
      version you are using. We track that information, along with the pages you
      are visiting, page load timing, and which website referred you for
      statistical purposes like conversion rates and to test new designs. We
      sometimes track specific link clicks to help inform some design decisions.
      These web analytics data are tied to your IP address and user account if
      applicable and you are signed into our Services.
      <p className='sub-header-modal'>Cookies and Do Not Track</p>
      We do use persistent first-party cookies to store certain preferences,
      make it easier for you to use our applications, and support some in-house
      analytics. A cookie is a piece of text stored by your browser to help it
      remember your login information, site preferences, and more. You can
      adjust cookie retention settings in your own browser. To learn more about
      cookies, including how to view which cookies have been set and how to
      manage and delete them, please visit: www.allaboutcookies.org.
      <p className='sub-header-modal'>Voluntary correspondence</p>
      When you write pickmytrade with a question or to ask for help, we keep
      that correspondence, including the email address, so that we have a
      history of past correspondences to reference if you reach out in the
      future.
      <br /> We also store any information you volunteer like surveys. Sometimes
      when we do user interviews, we may ask for your permission to record the
      conversation for future reference or use. We only do so if you give your
      express consent.
      <br />
      <p className='sub-header-modal'>Information we do not collect</p>
      We don’t collect any characteristics of protected classifications
      including age, race, gender, religion, sexual orientation, gender
      identity, gender expression, or physical and mental abilities or
      disabilities. You may provide these data voluntarily, such as if you
      include a pronoun preference in your email signature when writing into our
      Support team.
      <br /> We also do not collect any biometric data. You may be given the
      option to add a picture to your user profile, which could be a real
      picture of you or a picture of something else that represents you best. We
      do not extract any information from profile pictures: they are for your
      use alone.
      <br />
      <p className='sub-header-modal'>
        When we access or share your information
      </p>
      Our default practice is to not access your information. The only times
      we’ll ever access or share your info are:
      <br /> To provide products or services you’ve requested. We do use some
      third-party services to run our applications and only to the extent
      necessary process some or all of your personal information via these third
      parties. Having sub-processors means we are using technology to access
      your data. No pickmytrade human looks at your data for these purposes.
      <br /> To help you troubleshoot or squash a software bug, with your
      permission. If at any point we need to access your account to help you
      with a Support case, we will ask for your consent before proceeding.
      <br /> When required under applicable law.
      <br /> If pickmytrade is acquired — we don’t plan on that, but if it
      happens — we’ll notify you well before any info about you is transferred
      and becomes subject to a different privacy policy.
      <br />
      <p className='sub-header-modal'>
        Your rights with respect to your information
      </p>
      At pickmytrade, we apply the same data rights to all users, regardless of
      their location. Currently, the most privacy-forward regulations in place
      are the European Union’s General Data Protection Regulation (“GDPR”).
      pickmytrade recognizes all of the rights granted in these regulations,
      except as limited by applicable law. These rights include:
      <ul>
        <li>
          Right to Know. You have the right to know what personal information is
          collected, used, shared or sold. We outline both the categories and
          specific bits of data we collect, as well as how they are used, in
          this privacy policy.
        </li>
        <li>
          Right of Access. This includes your right to access the personal
          information we gather about you, and your right to obtain information
          about the sharing, storage, security and processing of that
          information.
        </li>
        <li>
          Right to Correction. You have the right to request correction of your
          personal information.
        </li>
        <li>
          Right to Erasure / “To be Forgotten”. This is your right to request,
          subject to certain limitations under applicable law, that your
          personal information be erased from our possession and, by extension,
          all of our service providers. Fulfillment of some data deletion
          requests may prevent you from using pickmytrade services because our
          applications may then no longer work. In such cases, a data deletion
          request may result in closing your account.
        </li>
        <li>
          Right to Complain. You have the right to make a complaint regarding
          our handling of your personal information with the appropriate
          supervisory authority. To identify your specific authority or find out
          more about this right, EU individuals should go to
          https://edpb.europa.eu/about-edpb/about-edpb/members_en.
        </li>
        <li>
          Right to Restrict Processing. This is your right to request
          restriction of how and why your personal information is used or
          processed, including opting out of sale of personal information.
          (Again: we never have and never will sell your personal data).
        </li>
        <li>
          Right to Object. You have the right, in certain situations, to object
          to how or why your personal information is processed.
        </li>
        <li>
          Right to Portability. You have the right to receive the personal
          information we have about you and the right to transmit it to another
          party.
        </li>
        <li>
          Right to not be subject to Automated Decision-Making. You have the
          right to object and prevent any decision that could have a legal, or
          similarly significant, effect on you from being made solely based on
          automated processes. This right is limited, however, if the decision
          is necessary for performance of any contract between you and us, is
          allowed by applicable law, or is based on your explicit consent.
        </li>
        <li>
          Right to Non-Discrimination. This right stems from the CCPA. We do not
          and will not offer you different services, or give you a lower level
          of user service because you have exercised your data privacy rights.
          However, the exercise of certain rights (such as the right “to be
          forgotten”) may, by virtue of your exercising those rights, prevent
          you from using our Services.
        </li>
      </ul>
      Many of these rights can be exercised by signing in and directly updating
      your account information.
      <br />
      If you have questions about exercising these rights or need assistance,
      please let us know through the Contact Us form. For requests to delete
      personal information or know what personal information has been collected,
      we will first verify your identity using a combination of at least two
      pieces of information already collected including your user email address.
      If an authorized agent is corresponding on your behalf, we will first need
      written consent with a signature from the account holder before
      proceeding.
      <br />
      If you are in the EU, you can identify your specific authority to file a
      complaint or find out more about GDPR, at
      https://edpb.europa.eu/about-edpb/about-edpb/members_en.
      <br />
      <p className='sub-header-modal'>How we secure your data</p>
      All data is encrypted via SSL/TLS when transmitted from our servers to
      your browser. Some of the database data are also encrypted.
      <br />
      For products, some data are not encrypted while they live in our database
      (since it needs to be ready to send to you when you need it), but we go to
      great lengths to secure your data at rest. For more information about how
      we keep your information secure, please review our Security Overview.
      <br />
      <p className='sub-header-modal'>
        What happens when you delete data in your account
      </p>
      We give you the option to delete your data. Anything you delete is gone
      from the application. <br />
      We also delete your data after an account is closed. This applies both for
      cases when an account owner directly closes and for accounts closed by us.
      <br />
      The account data might remain in our temporary backups for up to 90 days
      but afterwards it will be deleted forever.
      <br />
      Location of site and data
      <br />
      Our services and other web properties are distributed across several
      locations across America, Europe and Asia. Please be aware that any
      information you provide to us might be transferred to and stored in a
      jurisdiction different from your residency. By using our Site,
      participating in any of our services and/or providing us with your
      information, you consent to this transfer.
      <br />
      Transfer of personal data from the EU. The GDPR requires that any data
      transferred out of the EU must be treated with the same level of
      protection that the EU privacy laws grant. That is why since GDPR went
      into effect, PickMyTrade has offered a data processing addendum.
      <br />
      We have incorporated a Data Processing Addendum to our Terms of Service
      that is in effect when the GDPR applies to your use of PickMyTrade
      Services to process User Data as defined in the DPA.
      <p className='sub-header-modal'>Changes & questions</p>
      We may update this policy as needed to comply with relevant regulations
      and reflect any new practices. <br />
      Have any questions, comments, or concerns about this privacy policy, your
      data, or your rights with respect to your information? Send us an email to
      contact@pickmytrade.com

      <p className='end-text'>This website is managed by M/s Shalini Garg</p>
    </div>
  );
};

export default PrivacyPolicy;
