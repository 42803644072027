import React, { useEffect } from 'react';
import PrivacyPolicy from '../../../Components/TermsAndConditions/PrivacyPolicy';

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='privacy-ctr'>
      <PrivacyPolicy />
    </div>
  );
};

export default Privacy;
