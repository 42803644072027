import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import './goback.css';

const GoBack = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(-1);
  };

  return (
    <div className='go-back-ctr' onClick={onClick}>
      <ArrowLeftOutlined />
      <p>Back</p>
    </div>
  );
};

export default GoBack;
