import axios from "axios";
import { getToken } from "../../../Utils/settings";
import { getCalenderEventsUrl } from "../Api";

export const getCalenderEventsAction = ({ payload }) => {
  const tokenHeader = getToken();

  return new Promise((resolve, reject) => {
    axios
      .post(getCalenderEventsUrl, payload, tokenHeader)
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};
