import { API_URL } from "../../Utils/settings";

export const getPlanetAnglesUrl = API_URL + '/pick-my-trade/get-angles';

export const downloadCombinationsUrl =
  API_URL + '/pick-my-trade/download-combinations';

  export const getAngleColumnUrl =
    API_URL + '/pick-my-trade/get-angle-column-name';

  export const getAngleColumnValuesUrl =
    API_URL + '/pick-my-trade/get-angle-column-values';