import React, { useEffect } from 'react';

import './planetAngle.css';
import Dashboards from '../Dashboard/Dashboards';
import { notification } from 'antd';
import { SmileFilled } from '@ant-design/icons';

export default function PlanetAngles() {
  const [api, contextHolder] = notification.useNotification();

  const new_User = localStorage.getItem('new_user');
  const loginUser = JSON.parse(localStorage.getItem('logInUser'));

  useEffect(() => {
    if (new_User && JSON.parse(new_User) === true) {
      api.open({
        message: (
          <p className='notification-title'>{`Welcome ${loginUser.name}`} </p>
        ),
        description:
          'Your 15-day trial is now live! Enjoy exploring our features. Need help? Just ask!',
        icon: <SmileFilled className='success' />,
      });

      setTimeout(() => {
        api.destroy();
        localStorage.setItem('new_user', JSON.stringify(false));
      }, 10000);
    }
  }, []);

  return (
    <>
      {contextHolder}
      <Dashboards />
    </>
  );
}
