import dayjs from 'dayjs';
import {
  generateSymbol,
  makeApiRequest,
  parseFullSymbol,
} from '../../Utils/helpers';
import {
  getCandleDataAction,
  getSymbolsAction,
} from '../../Screens/Dashboard/Action';
import { getRandomColor } from '../../Utils/settings';
import {
  addDefaultTurningPoint,
  setChartData,
  setSearchParams,
  setTurningPoints,
} from '../../redux/dashboardSlice/dashSlice';
import { useDispatch } from 'react-redux';
import { store } from '../../redux/store';
import { useLocation } from 'react-router-dom';
import { notification } from 'antd';

// dayjs.extend(window.dayjs_plugin_utc)
// dayjs.extend(window.dayjs_plugin_timezone)
// dayjs.tz.setDefault("Asia/Kolkata")

export default {
  onReady: (callback) => {
    setTimeout(() => callback(configurationData));
  },
  searchSymbols: async (
    userInput,
    exchange,
    symbolType,
    onResultReadyCallback
  ) => {
    const { data, error, message } = await getSymbolsAction();

    const newData = data
      .filter((stock) =>
        String(stock).toUpperCase().includes(String(userInput).toUpperCase())
      )
      .sort();

    onResultReadyCallback(
      newData.map((item, index) => ({
        symbol: item,
        full_name: item,
        description: '',
        exchange: `pickMyTrade`,
        ticker: item,
        type: 'stock',
      }))
    );
  },
  resolveSymbol: async (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback,
    extension
  ) => {
    setTimeout(() => {
      onSymbolResolvedCallback({
        ticker: symbolName,
        name: symbolName,
        description: symbolName,
        type: 'stock_index',
        session: '24x7',
        timezone: 'Asia/Kolkata',
        exchange: '',
        minmov: 1,
        pricescale: 100,
        has_intraday: false,
        visible_plots_set: 'ohlc',
        has_weekly_and_monthly: false,
        supported_resolutions: ['1D'],
        volume_precision: 2,
        data_status: 'streaming',
        base_name: ['NIFTY'],
        legs: ['NIFTY'],
        full_name: 'nse:NIFTY',
        pro_name: 'nse:NIFTY',
        price_sources: [],
      });
    }, 0);
  },
  getBars: async (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback
  ) => {
    const from = dayjs(new Date(periodParams.from * 1000)).format('YYYY-MM-DD');
    const to = dayjs(new Date(periodParams.to * 1000)).format('YYYY-MM-DD');

    const totalDays = dayjs(to).diff(dayjs(from), 'day');

    console.log('totalDays', totalDays);

    if(totalDays < 50 || !totalDays) return onHistoryCallback([], { noData: true });

    const {
      dashboard: { chartData, searchParams },
      planet: { planet_angles },
    } = store.getState();

    if (symbolInfo.name !== searchParams.symbol) {
      store.dispatch(
        setSearchParams({
          symbol: symbolInfo.name,
        })
      );
    }

    const location = window.location.hash;

    if (location === '#/trade/planet_angles') {
      const bar = planet_angles.map((bar) => ({
        time: dayjs(bar.date).add(1, 'day').unix() * 1000,
        low: bar.Low,
        high: bar.High,
        open: bar.Open,
        close: bar.Close,
      }));

      if (bar.length > 0) {
        setTimeout(() => {
          onHistoryCallback(bar, { noData: false });
        }, 0);
      } else {
        onHistoryCallback([], { noData: true });
      }
    } else {
      const formatBarData = (data) => {
        const bars = data.map((bar) => ({
          time: dayjs(bar.time).add(1, 'day').unix() * 1000,
          low: bar.low,
          high: bar.high,
          open: bar.open,
          close: bar.close,
        }));

        if (bars.length > 0) {
          setTimeout(() => {
            onHistoryCallback(bars, { noData: false });
          }, 0);
        } else {
          console.log('emptyyyyy')
          onHistoryCallback([], { noData: true });
        }
      };

      const getTurningPoints = (data) => {
        const tp = data
          .filter((vv) => vv.swingpoint)
          .map((item) => {
            return {
              ...item,
              greenStick: Number(item.close) > Number(item.open),
            };
          });

        store.dispatch(setTurningPoints(tp));
      };
      //F
      try {
        const payload = [
          {
            // exchange_name: '',
            symbol: symbolInfo.name,
            // location: 'Mumbai',
            opening_time: '09:15:00',
            years: 15,
          },
        ];

        getCandleDataAction({ payload }).then(({ error, data, message }) => {
          if (!error) {
            // store.dispatch(setChartData(data));

            formatBarData(data);
            getTurningPoints(data);
          } else {
            notification.error({message})
            onHistoryCallback([], { noData: true });
          }
        });
        // formatBarData(barData);
        // getTurningPoints(barData);
        // if (barData.length > 0) {
        // } else {
        //
        // }
      } catch (error) {
        onErrorCallback(error);
      }
    }
  },
  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback
  ) => {},
  unsubscribeBars: (subscriberUID) => {},
  getMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
    const {
      dashboard: { chartData, collapseOppened, uniqueDates },
    } = store.getState();

    const location = window.location.hash;

    if (location === '#/trade/planet_angles') {
      const candleMarkers = store.getState().planet.candleMarkers;

      const keys = Object.keys(candleMarkers);
      if (keys.length === 0) {
        onDataCallback([], { noData: true });
      }

      const bars = [];
      keys.forEach((key) => {
        // const arr = []
        candleMarkers[key].forEach((vv, ii) => {
          bars.push({
            id: `${key}_${ii}`,
            time: dayjs(vv.time).add(1, 'day').unix(),
            // color: 'red',
            text: [
              `${dayjs(vv.time).format('DD MMM YYYY')} - ${key} - ${
                vv.isLordShip ? vv.text.slice(1) : vv.text
              }`,
            ],
            label: vv.text,
            labelFontColor: [`${vv.color}`],
            minSize: 25,
          });
          // tvWidget
          //   .activeChart()
          //   .createShape(
          //     { time: dayjs(vv.time).add(1, 'day').unix() },
          //     { shape: 'arrow_up', lock: true, text: vv.text }
          //   );
        });
      });
      // const bars = filteredData.map((bar, index) => ({
      //   id: index,
      //   time: dayjs(bar.time).add(1, 'day').unix(),
      //   color: 'Orange',
      //   text: [`${dayjs(bar.time).format('DD MMM YYYY')}`],
      //   label: 'TP',
      //   labelFontColor: 'black',
      //   minSize: 25,
      // }));

      if (bars.length > 0) return onDataCallback(bars, { noData: false });
      else return onDataCallback([], { noData: true });
    } else if (location === '#/trade/combinations') {
      if (collapseOppened.length === 0 || collapseOppened[0] === '1') {
        const filteredData = chartData.filter((item) => item.swingpoint);

        const bars = filteredData.map((bar, index) => ({
          id: index,
          time: dayjs(bar.time).add(1, 'day').unix(),
          color: 'Orange',
          text: [`${dayjs(bar.time).format('DD MMM YYYY')}`],
          label: 'TP',
          labelFontColor: 'black',
          minSize: 25,
          price: bar.close,
        }));

        if (bars.length > 0) {
          onDataCallback(bars, { noData: false });
        } else {
          onDataCallback([], { noData: true });
        }
      } else if (collapseOppened[0] === '2') {
        const bars = uniqueDates.map((bar, index) => ({
          id: index,
          time: dayjs(bar.date).add(1, 'day').unix(),
          color: 'Orange',
          text: [
            `${dayjs(bar.date).format('DD MMM YYYY')} - Pivot Point(${
              bar.pivot_point
            })`,
          ],
          label: 'TP',
          labelFontColor: 'black',
          minSize: 25,
        }));

        if (bars.length > 0) {
          onDataCallback(bars, { noData: false });
        } else {
          onDataCallback([], { noData: true });
        }
      }
    } else {
      return onDataCallback([], { noData: true });
    }

    // const payload = [
    //   {
    //     exchange_name: 'NSE',
    //     symbol: '^NSEI',
    //     location: 'Mumbai',
    //     opening_time: '09:15:00',
    //     years: 15,
    //   },
    // ];
    // ff
  },
};

// DatafeedConfiguration implementation
// ...
// Obtains all symbols for all exchanges supported by CryptoCompare API
async function getAllSymbols() {
  const data = await makeApiRequest('data/v3/all/exchanges');
  let allSymbols = [];

  for (const exchange of configurationData.exchanges) {
    const pairs = data.Data[exchange.value].pairs;

    for (const leftPairPart of Object.keys(pairs)) {
      const symbols = pairs[leftPairPart].map((rightPairPart) => {
        const symbol = generateSymbol(
          exchange.value,
          leftPairPart,
          rightPairPart
        );
        return {
          symbol: symbol.short,
          full_name: symbol.full,
          description: symbol.short,
          exchange: exchange.value,
          type: 'crypto',
        };
      });
      allSymbols = [...allSymbols, ...symbols];
    }
  }
  return allSymbols;
}

const configurationData = {
  // Represents the resolutions for bars supported by your datafeed
  supported_resolutions: ['1D'],
  // The `exchanges` arguments are used for the `searchSymbols` method if a user selects the exchange
  exchanges: [
    { value: 'binanceusa', name: 'binanceusa', desc: 'binanceusa' },
    // { value: 'Kraken', name: 'Kraken', desc: 'Kraken bitcoin exchange' },
  ],
  supports_marks: true,
  // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
  // symbols_types: [{ name: 'crypto', value: 'crypto' }],
};

//
// getBars: async (
//   symbolInfo,
//   resolution,
//   periodParams,
//   onHistoryCallback,
//   onErrorCallback
// ) => {
//   try {
//     const payload = [
//       {
//         exchange_name: 'NSE',
//         symbol: '^NSEI',
//         location: 'Mumbai',
//         opening_time: '09:15:00',
//         years: 15,
//       },
//     ];

//     getCandleDataAction({ payload }).then(({ error, data, message }) => {
//       if (!error) {
//         const bars = data.map((bar) => ({
//           time: dayjs(bar.time).unix(),
//           low: bar.low,
//           high: bar.high,
//           open: bar.open,
//           close: bar.close,
//         }));

//         onHistoryCallback(bars, { noData: false });
//       } else {
//         onHistoryCallback([], { noData: true });
//       }
//     });
//   } catch (error) {
//     onErrorCallback(error);
//   }
// }
