import { getCombinationAction } from '../../../Screens/Dashboard/Action';
import {
  setCombinationLoader,
  setMoonPhaseCombinations,
} from '../../../redux/dashboardSlice/dashSlice';
import { notification } from 'antd';

export default async function getCombinationsCall({
  dispatch,
  initialFilterObj = {},
  page_number = 1,
  getCombinationController,
}) {
  const payload = [{ ...initialFilterObj }];
  // Setting Loading True

  try {
    dispatch(setCombinationLoader(true));

    await getCombinationAction({
      payload,
      page_number,
      getCombinationController,
      dispatch,
    }).then(({ error, message, data }) => {
      if (!error) {
        dispatch(setMoonPhaseCombinations(data));
      } else {
        notification.error({ message });
      }
    });

    // Setting Loading False
    dispatch(setCombinationLoader(false));
  } catch (error) {
    console.log(error);
  }
}
