import axios from 'axios';
import { getToken } from '../../../Utils/settings';
import {
  cancelSubscriptionUrl,
  createPhonePeSubscriptionUrl,
  createSubscriptionUrl,
  getAllMySubscriptionsUrl,
  getAllPlansUrl,
  getPhonePeSubscriptionProductUrl,
  pauseSubscriptionUrl,
  phonePeGetActiveSubscriptionUrl,
  phonePeVerifyTransactionUrl,
  resumeSubscriptionUrl,
} from '../Api';

export const getAllPlansAction = () => {
  const tokenHeader = getToken();

  return new Promise((resolve, reject) => {
    axios
      .get(getAllPlansUrl, tokenHeader)
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};

export const createSubscriptionAction = ({ payload }) => {
  const tokenHeader = getToken();

  return new Promise((resolve, reject) => {
    axios
      .post(createSubscriptionUrl, payload, tokenHeader)
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};

export const getAllMySubscriptionsAction = () => {
  const tokenHeader = getToken();

  return new Promise((resolve, reject) => {
    axios
      .get(getAllMySubscriptionsUrl, tokenHeader)
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};

export const pauseSubscriptionAction = ({ sub_id }) => {
  const tokenHeader = getToken();

  return new Promise((resolve, reject) => {
    axios
      .put(
        `${pauseSubscriptionUrl}?stripe_subscription_id=${sub_id}`,
        {},
        tokenHeader
      )
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};

export const resumeSubscriptionAction = ({ sub_id }) => {
  const tokenHeader = getToken();

  return new Promise((resolve, reject) => {
    axios
      .put(
        `${resumeSubscriptionUrl}?stripe_subscription_id=${sub_id}`,
        {},
        tokenHeader
      )
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};

export const cancelSubscriptionAction = ({ sub_id }) => {
  const tokenHeader = getToken();

  return new Promise((resolve, reject) => {
    axios
      .delete(
        `${cancelSubscriptionUrl}?stripe_subscription_id=${sub_id}`,
        tokenHeader
      )
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};

// Phone Pe Payment
export const getPhonePeSubscriptionProductAction = () => {
  const tokenHeader = getToken();

  return new Promise((resolve, reject) => {
    axios
      .get(getPhonePeSubscriptionProductUrl, tokenHeader)
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};

export const createPhonePeSubscriptionAction = ({ payload }) => {
  const tokenHeader = getToken();

  return new Promise((resolve, reject) => {
    axios
      .post(createPhonePeSubscriptionUrl, payload, tokenHeader)
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};

export const phonePeVerifyTransactionAction = ({ product_id }) => {
  const tokenHeader = getToken();

  return new Promise((resolve, reject) => {
    axios
      .post(
        `${phonePeVerifyTransactionUrl}?subscription_product_id=${product_id}`,
        {},
        tokenHeader
      )
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};

export const phonePeGetActiveSubscriptionAction = () => {
  const tokenHeader = getToken();

  return new Promise((resolve, reject) => {
    axios
      .get(phonePeGetActiveSubscriptionUrl, tokenHeader)
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};
