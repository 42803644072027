import { Button, Form, Input, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { setNewPasswordAction } from './Action';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const SetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const onFinish = async (values) => {
    const payload = {
      token: token,
      password1: values.password1,
      password2: values.password2,
    };

    setLoading(true);

    await setNewPasswordAction({ payload })
      .then(({ data, error, message }) => {
        if (!error) {
          notification.success({
            message: 'Password Set Successfully',
            description: 'Redirecting back to the login page.',
          });

          setTimeout(() => {
            localStorage.clear();
            dispatch({ type: 'USER_LOGOUT' });
            navigate('/auth/login', { replace: true });
          }, 3000);
        } else {
          notification.error({
            message: 'Password Reset Failed',
            description:
              'We encountered an error while attempting to reset your password. Please try again later or contact support for assistance.',
          });
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  useEffect(() => {
    const currentUrl = window.location.hash;

    var emailTokenRegex = /email_token=([^&]+)/;

    // Use the exec() method to find the match
    var match = emailTokenRegex.exec(currentUrl);

    // Extract the email token (if found)
    var emailToken = match ? match[1] : null;

    if (emailToken) {
      setToken(emailToken);
    } else {
      // console.log('No email token found in URL');
      navigate('/auth/login', { replace: true });
    }
  }, []);

  return (
    <section className='login-ctr'>
      <div className='login-box'>
        <h2 className='login_title'>Set New Password</h2>
        <p className='login_subtitle'>
          Welcome back! It's time to secure your account by setting a new
          password. Enter your details below to complete the process and get
          started on your journey with us.
        </p>

        <Form className='login-form_ctr' onFinish={onFinish}>
          <p className='item_title'>New Password</p>
          <Form.Item
            name={'password1'}
            rules={[
              {
                required: true,
                message: 'Password is required!',
              },
            ]}
          >
            <Input.Password placeholder='New Password' className='form-item' />
          </Form.Item>

          <p className='item_title'>Confirm New Password</p>
          <Form.Item
            name={'password2'}
            dependencies={['password1']}
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password1') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Passwords do not match!'));
                },
              }),
            ]}
          >
            <Input.Password
              placeholder='Confirm New Password'
              className='form-item'
            />
          </Form.Item>

          <Form.Item>
            <Button
              loading={loading}
              // icon={<LoginOutlined />}
              htmlType='submit'
              className='form-item form-item-btn '
            >
              Confirm And Change
            </Button>
          </Form.Item>
        </Form>
      </div>
    </section>
  );
};

export default SetPassword;
