import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { dateFormat, trueFalse } from '../../Utils/settings';

const initialState = {
  planet_angles: [],
  planet_marker: [],
  selectedChartAngles: {},
  candleMarkers: {},
  indicatorData: {},
  selectedAlphabets: [],
};

export const planetSlice = createSlice({
  name: 'planet',
  initialState,
  reducers: {
    setAlphabets: (state, { payload }) => {
      const { type, data } = payload;
      if (type === 'ADD') {
        state.selectedAlphabets.push(data);
      } else if (type === 'REMOVE') {
        state.selectedAlphabets = state.selectedAlphabets.filter(
          (item) => item !== data
        );
      } else {
        state.selectedAlphabets = [];
      }
    },
    setIndicatorData: (state, { payload }) => {
      state.indicatorData = payload;
    },
    setPlanetAngles: (state, { payload }) => {
      state.planet_angles = payload;
    },
    setSelectedAngles: (state, { payload }) => {
      const regex = /^-?[0-9]+(\.[0-9]+)?$/;
      const numberString = /^\d+$|^'\d+'$/;

      const { type, data, color, alphabet } = payload;

      const filteredAngles = state.planet_angles
        .map((item) => ({
          [data]: item[data],
          date: item.date,
        }))
        .filter((vv) => vv[data] !== null);

      const angleData = filteredAngles[0][data];

      const temp = filteredAngles[0][data];
      const isNumberString =
        String(filteredAngles[0][data]) === temp && numberString.test(temp);
      const dataIsString = !regex.test(angleData);
      const isLordShip = trueFalse.test(filteredAngles[0][data]);

      if (type === 'ADD') {
        if (dataIsString || isNumberString) {
          let arr = filteredAngles
            .map((angle) => {
              return {
                time: dayjs(angle['date']).format(dateFormat),
                position: 'aboveBar',
                color: color,
                shape: 'arrowDown',
                text: isLordShip
                  ? `${alphabet}${String(angle[data])}`
                  : String(angle[data]),
                lordShip: String(angle[data]),
                isLordShip: isLordShip,
              };
            })
            .sort((a, b) => dayjs(a.time).valueOf() - dayjs(b.time).valueOf());

          let outputArray = [];

          let uniqueTexts = '';

          const propertyToCheck = isLordShip ? 'lordShip' : 'text';

          // "45" true
          // "hello" false
          //  45 false

          if (isNumberString) {
            outputArray = arr;
          } else {
            arr.forEach((item) => {
              if (!uniqueTexts) {
                uniqueTexts = item[propertyToCheck];
                outputArray.push(item);
              } else if (uniqueTexts !== item[propertyToCheck]) {
                uniqueTexts = item[propertyToCheck];
                outputArray.push(item);
              }
            });
          }

          state.candleMarkers = {
            ...state.candleMarkers,
            [data]: outputArray,
          };
        } else {
          let arr = filteredAngles
            .map((angle) => ({
              value: angle[data],
              time: dayjs(angle['date']).format(dateFormat),
              color: color,
            }))
            .sort((a, b) => dayjs(a.time).valueOf() - dayjs(b.time).valueOf());

          state.selectedChartAngles = {
            ...state.selectedChartAngles,
            [data]: arr,
          };
        }
      } else if (type === 'REMOVE') {
        if (dataIsString || isNumberString) {
          delete state.candleMarkers[data];
        } else {
          delete state.selectedChartAngles[data];
        }
      }
    },
    resetSelectedAngles: (state) => {
      state.candleMarkers = {};
      state.selectedChartAngles = {};
    },
    setPlanetMarker: (state, { payload }) => {
      const mark = [
        {
          time: payload,
          position: 'aboveBar',
          color: '#0F6CBD',
          shape: 'arrowDown',
          text: dayjs(payload).format('DD MMM'),
        },
      ];

      state.planet_marker = mark;
    },
  },
});

export const {
  setIndicatorData,
  setPlanetAngles,
  setPlanetMarker,
  setSelectedAngles,
  resetSelectedAngles,
  setAlphabets,
} = planetSlice.actions;

export default planetSlice.reducer;
