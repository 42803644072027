import { CrosshairMode } from 'lightweight-charts';
import { setScrollPosition } from '../redux/dashboardSlice/dashSlice';
import Logo from './../Assets/logo.png';

export const API_URL = process.env.REACT_APP_API_URL;

export const pickMyTradeHelpMail = 'support@pickmytrade.com';

export const zipyCode = 'db9b2ad5';

// Static Links
export const tradingViewLink = 'https://in.tradingview.com';

// Images
export const tradingViewLogo = Logo;

export function isLoggedIn() {
  const token = localStorage?.getItem('token');
  return !!token;
}

export function isSupportedEmail(email) {
  // Regular expressions for checking email domains
  const gmailRegex = /@gmail\.com$/i;
  const yahooRegex = /@yahoo\.com$/i;
  const hotmailRegex = /@hotmail\.com$/i;

  // Check if the email matches any of the supported domains
  return (
    gmailRegex.test(email) || yahooRegex.test(email) || hotmailRegex.test(email)
  );
}

export const trueFalse = /^(true|false)$/i;

export function getRandomAlphabet(inputAlphabet) {
  // Check if the input is a single uppercase alphabet character
  if (/^[A-Z]$/.test(inputAlphabet)) {
    // Get the character code of the input alphabet
    const inputCharCode = inputAlphabet.charCodeAt(0);

    // Calculate the character code of the next alphabet
    const nextCharCode =
      ((inputCharCode - 'A'.charCodeAt(0) + 1) % 26) + 'A'.charCodeAt(0);

    // Convert the character code to the corresponding alphabet letter
    const nextAlphabet = String.fromCharCode(nextCharCode);

    return nextAlphabet;
  } else {
    // If the input is not a single uppercase alphabet character, return null or handle the error as needed
    return null;
  }
}

// CandleSeries
const greenStickColor = 'rgba(0, 150, 136, 0.8)';
const redStickColor = 'rgba(255,82,82, 0.8)';

export const candleStickSeries = {
  upColor: greenStickColor,
  downColor: redStickColor,

  borderDownColor: redStickColor,
  borderUpColor: greenStickColor,

  wickDownColor: redStickColor,
  wickUpColor: greenStickColor,
};

export const areaSeriesConfig = {
  topColor: 'rgba(38,198,218, 0.56)',
  bottomColor: 'rgba(38,198,218, 0.04)',
  lineColor: 'rgba(38,198,218, 1)',
  lineWidth: 2,
};

export const currencyEnums = {
  INR: 1,
  USD: 2,
};

export const billingCycleEnums = {
  FREE: 'FREE',
  MONTHLY: 1,
  YEARLY: 2,
  LIFETIME: 3,
};

export const plansBenefits = {
  FREE: [
    'Supports two symbols.',
    'Includes one astrological indicator for a planet.',
    'Supports all technical indicators.',
    'Requests for adding new symbols are included.',
  ],
  [billingCycleEnums['MONTHLY']]: [
    'Unlimited symbol support.',
    'Includes all planet astrological indicators.',
    'Supports all technical indicators.',
    'Requests for adding new symbols are included.',
  ],
  [billingCycleEnums['YEARLY']]: [
    'Unlimited symbol support.',
    'Includes all planet astrological indicators.',
    'Supports all technical indicators.',
    'Requests for adding new symbols are included.',
  ],
};

export function getKeyByValue(object, targetValue) {
  for (const key in object) {
    if (object.hasOwnProperty(key) && object[key] === targetValue) {
      return key;
    }
  }
  return null; // Return null if the value is not found
}

export const lineSeriesConfig = {
  color: getRandomColor(),
  lineWidth: 2,
};

export const chartConfig = {
  layout: {
    background: {
      type: 'solid',
    },
    textColor: 'rgba(0, 0, 0, 0.9)',
  },
  grid: {
    vertLines: {
      color: 'rgba(186, 191, 194, 0.5)',
    },
    horzLines: {
      color: 'rgba(186, 191, 194, 0.5)',
    },
  },
  crosshair: {
    mode: CrosshairMode.Normal,
  },
  rightPriceScale: {
    borderColor: 'rgba(197, 203, 206, 0.8)',
  },
};

export const getToken = () => {
  const token = JSON.parse(localStorage.getItem('token'));
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
};

export const goToPositionOnChart = ({ date, data }) => {
  // date format 2023-01-03
  const candleData = data;

  const lastIndex = candleData.findIndex((item) => item.time === date);

  if (lastIndex !== -1) {
    const positionFromBack = candleData.length - 1 - lastIndex - 50;
    return positionFromBack;
    // dispatch(setScrollPosition(positionFromBack));
  } else return null;
};

export const resetChartPosition = ({ dispatch }) => {
  dispatch(setScrollPosition(0));
};

export const validateNumber = (_, value) => {
  const regex = /^[0-9]+(\.[0-9]+)?$/;
  if (value && !regex.test(value)) {
    return Promise.reject('Please enter a valid number.');
  }
  return Promise.resolve();
};

function hexToRgb(hex) {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

// Helper function to convert HSL to RGB
function hslToRgb(hsl) {
  var hslValues = hsl.match(/\d+/g).map(Number);
  var h = hslValues[0] / 360;
  var s = hslValues[1] / 100;
  var l = hslValues[2] / 100;

  var r, g, b;
  if (s === 0) {
      r = g = b = l; // Achromatic (gray)
  } else {
      var hueToRgb = function (p, q, t) {
          if (t < 0) t += 1;
          if (t > 1) t -= 1;
          if (t < 1 / 6) return p + (q - p) * 6 * t;
          if (t < 1 / 2) return q;
          if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
          return p;
      };

      var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      var p = 2 * l - q;
      r = hueToRgb(p, q, h + 1 / 3);
      g = hueToRgb(p, q, h);
      b = hueToRgb(p, q, h - 1 / 3);
  }

  var rInt = Math.round(r * 255);
  var gInt = Math.round(g * 255);
  var bInt = Math.round(b * 255);
  return `rgb(${rInt}, ${gInt}, ${bInt})`;
}

export function getRandomColor() {
  var hue = Math.floor(Math.random() * 360); // Random hue value
    var saturation = Math.floor(Math.random() * 100); // Random saturation value
    var lightness = Math.floor(Math.random() * 50); // Keep lightness low for dark colors
    var color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;

    // Convert HSL to RGB
    var rgbColor = hslToRgb(color);
    return rgbColor;
}

// #ffffff


export const colIncluded = [
  'First',
  'Second',
  'Third',
  'Fourth',
  'Fifth',
  'Sixth',
  'Seventh',
  'Eighth',
  'Ninth',
  'Tenth',
  'Eleventh',
  'Twelfth',
  'Mercury',
  'Venus',
  'Mars',
  'Jupiter',
  'Saturn',
  'True_Node',
  'dates_sum',
  'Sun',
];

export const dateFormat = 'YYYY-MM-DD'

export const planetAngles = [
  { label: 'Mercury', value: 'mercury' },
  { label: 'Venus', value: 'venus' },
  { label: 'Mars', value: 'mars' },
  { label: 'Jupiter', value: 'jupiter' },
  { label: 'Saturn', value: 'saturn' },
  { label: 'True Node', value: 'true_node' },
  { label: 'Sun', value: 'sun' },
];

const combinationMode = {
  geocentric: 0,
  heliocentric: 1
}

export const combinationModeOptions = [
  { label: 'Geocentric', value: combinationMode['geocentric'] },
  { label: 'Heliocentric', value: combinationMode['heliocentric'] },
]

const neighbours = {
  5: 5,
  10: 10,
  15: 15,
  20: 20,
};

export const neighboursOptions = [
  { label: '5', value: neighbours['5'] },
  { label: '10', value: neighbours['10'] },
  { label: '15', value: neighbours['15'] },
  { label: '20', value: neighbours['20'] },
];

export const planetValuesOptions = [
  { label: 'Sign', value: 'sign' },
  { label: 'Element', value: 'element' },
  { label: 'Retrograde', value: 'retrograde' },
  { label: 'Abs Pos', value: 'abs_pos' },
];

export const testData = [
  {
    combinations: 'VenusTrue_Nodeangle_sum, Saturn_abs_pos_sum',
    values: '1.0, 1.0',
    dates: [
      {
        date: '2021-02-16',
        swing_count: true,
      },
      {
        date: '2011-12-28',
        swing_count: true, // Show Green
      },
      {
        date: '2017-07-07',
        swing_count: false, // Show Red
      },
      {
        date: '2009-11-09',
        swing_count: true,
      },
      {
        date: '2021-10-25',
        swing_count: true,
      },
    ],
    'count_day_-5': 1,
    'count_day_-4': 0,
    'count_day_-3': 0,
    'count_day_-2': 0,
    'count_day_-1': 0,
    count_day_0: 1,
    count_day_1: 0,
    count_day_2: 0,
    count_day_3: 0,
    count_day_4: 2,
    count_day_5: 2,
    sum_of_all_count: 6,
    total_count: 6,
    success_rate: 100,
    total_dates: [
      '2009-11-09',
      '2011-12-28',
      '2017-07-07',
      '2019-05-27',
      '2021-02-16',
      '2021-10-25',
    ],
  },
];

